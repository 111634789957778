<template>
    <div>
        <div class="modal-backdrop">
            <div class="modal modal-dialog-scrollable modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-light p-3">
                        <slot name="header">
                            <h5 class="modal-title" id="modalCenterTitle">{{title}}</h5>
                            <button type="button" class="close px-2 py-1 m-0" data-dismiss="modal" aria-label="Close" @click="close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </slot>
                    </div>
                    <div class="modal-body">
                        <slot name="body">
                            <div class="alert alert-warning" v-if="errors.length">
                                <b>Пожалуйста, исправьте указанные ошибки:</b>
                                <ul>
                                    <li v-for="(error, index) in errors" :key="'e-' + index">{{ error }}</li>
                                </ul>
                            </div>
                            <table class="table table-striped table-bordered table-hover" id="pupilData">
                                <tbody>
                                    <tr v-show="fields.subjectName === 'технология'">
                                      <th class="align-middle">Направление</th>
                                      <td class="align-middle">
                                        <select
                                            class="form-control align-middle" id="direction-type"
                                            v-model="fields['Направление']"
                                            @change="setDirectionType"
                                        >
                                          <option :value="data['Направление']" selected hidden>{{ data['Направление'] }}</option>
                                          <option v-for="keyName in Object.keys(technologyDirections)" :value="keyName">{{ keyName }}</option>
                                        </select>
                                      </td>
                                      <th class="align-middle">Виды практик</th>
                                      <td class="align-middle">
                                        <select
                                            class="form-control align-middle" id="action-type"
                                            v-model="fields['Виды практик']"
                                        >
                                          <option :value="data['Виды практик']" selected hidden>{{ data['Виды практик'] }}</option>
                                          <option v-for="val in technologyDirections[directionType]" :value="val">{{ val }}</option>
                                        </select>
                                      </td>
                                    </tr>

                                    <tr>
                                      <th class="align-middle">Снилс  <span class="text-danger">*</span></th>
                                      <td class="align-middle text-center">
                                        <input
                                            class="form-control align-middle"
                                            id = "snils" type="text"
                                            pattern="[0-9]+([,][0-9]{1,2})?"
                                            v-model="fields['Снилс']"
                                            :required = "true"
                                        />
                                      </td>
                                      <th class="align-middle">Максимальный балл</th>
                                      <td class="align-middle text-center">
                                      </td>
                                    </tr>

                                    <tr>
                                        <th class="align-middle">Фамилия <span class="text-danger">*</span></th>
                                        <td class="align-middle text-center">
                                            <input
                                                    class="form-control align-middle"
                                                    id = "surname"
                                                    name = "surname" type="text"
                                                    v-model="fields['Фамилия']"
                                                    :required = "true"
                                            />
                                        </td>
                                        <th class="align-middle">Имя <span class="text-danger">*</span></th>
                                        <td class="align-middle text-center">
                                            <input
                                                class="form-control align-middle"
                                                id = "name" type="text"
                                                v-model="fields['Имя']"
                                                :required = "true"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="align-middle">Отчество (при наличии)</th>
                                        <td class="align-middle text-center">
                                            <input
                                                class="form-control align-middle"
                                                id = "patronymic" type="text"
                                                v-model="fields['Отчество']"
                                            />
                                        </td>
                                        <th>Дата рождения <span class="text-danger">*</span></th>
                                        <td>
                                            <input
                                                class="form-control align-middle"
                                                id = "birth-date" type="date"
                                                v-model="fields['Дата рождения']"
                                                :required = "true"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="align-middle">Пол <span class="text-danger">*</span></th>
                                        <td>
                                            <select
                                                class="form-control align-middle" id="gender"
                                                v-model="fields['Пол']"
                                            >
                                                <option :value="data['Пол']" selected hidden>{{ data['Пол'] }}</option>
                                                <option :value="'Ж'">Ж</option>
                                                <option :value="'М'">М</option>
                                            </select>
                                        </td>
                                        <th class="align-middle">Участник с ОВЗ</th>
                                        <td>
                                            <select
                                                class="form-control align-middle" id="ovz"
                                                v-model="fields['Участник с ОВЗ (да/нет)']"
                                                v-if="!!data"
                                            >
                                                <option v-if="!data['Участник с ОВЗ (да/нет)']" :value="'нет'" selected hidden>нет</option>
                                                <option :value="'да'">да</option>
                                                <option :value="'нет'">нет</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="align-middle">Класс обучения <span class="text-danger">*</span></th>
                                        <td>
                                            <select
                                                class="form-control align-middle" id="edu_grade"
                                                v-model="fields['Класс обучения']" v-if="!!data"
                                            >
                                                <option v-if="!data['Класс обучения']" selected hidden></option>
                                                <option v-else :value="data['Класс обучения']" selected hidden>{{data['Класс обучения']}} класс</option>
                                                <option v-for="i in (1, 11)" :key="'edu_grade_' + i" :value="i">{{i}} класс</option>
                                            </select>
                                        </td>
                                        <th class="align-middle">Класс участия <span class="text-danger">*</span></th>
                                        <td>
                                            <select
                                                class="form-control align-middle" id="part_grade"
                                                v-model="fields['Класс участия']"
                                                v-if="!!data"
                                            >
                                                <option v-if="!data['Класс участия']" selected hidden ></option>
                                                <option v-else :value="data['Класс участия']" selected hidden>{{data['Класс участия']}} класс</option>
                                                <option v-for="i in (1, 11)" :key="'part_grade_' + i" :value="i">{{i}} класс</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>

                                    </tr>
                                    <tr>
                                        <th class="align-middle">Балл  <span class="text-danger">*</span></th>
                                        <td class="align-middle text-center">
                                            <input
                                                class="form-control align-middle"
                                                id = "mark" type="text"
                                                pattern="[0-9]+([,][0-9]{1,2})?"
                                                v-model="fields['Балл']"
                                                :required = "true"
                                            />
                                        </td>
                                        <th class="align-middle">Максимальный балл</th>
                                        <td class="align-middle text-center">
                                            <input
                                                class="form-control align-middle"
                                                id = "max-mark" type="text"
                                                pattern="[0-9]+([,][0-9]{1,2})?"
                                                v-model="fields['Максимальный балл']"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="align-middle">Статус  <span class="text-danger">*</span></th>
                                        <td>
                                            <select
                                                class="form-control align-middle" id="status"
                                                v-model="fields['Статус']"
                                                v-if="!!data"
                                            >
                                                <option selected hidden :value="data['Статус']">{{data['Статус']}}</option>
                                                <option :value="'участник'">участник</option>
                                                <option :value="'призёр'">призёр</option>
                                                <option :value="'победитель'">победитель</option>
                                            </select>
                                        </td>
                                        <th class="align-middle">Призёр МЭ прошлого года <span class="text-danger">*</span></th>
                                        <td>
                                            <select
                                                class="form-control align-middle" id="last-year-prizer"
                                                v-model="fields['Призёр муниципального этапа прошлого года']"
                                                v-if="!!data"
                                            >
                                                <option v-if="!data['Призёр муниципального этапа прошлого года'] === ''" :value="'нет'" selected hidden>нет</option>
                                                <option v-if="!!data['Призёр муниципального этапа прошлого года'] === ''" :value="data['Призёр муниципального этапа прошлого года']" selected hidden>{{data['Призёр муниципального этапа прошлого года']}}</option>
                                                <option :value="'да'">да</option>
                                                <option :value="'нет'">нет</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="align-middle">ФИО учителя</th>
                                        <td class="align-middle text-center">
                                            <input
                                                class="form-control align-middle"
                                                id = "teacher-name" type="text"
                                                v-model="fields['ФИО учителя']"
                                            />
                                        </td>
                                        <th class="align-middle">Место работы учителя</th>
                                        <td class="align-middle text-center">
                                            <textarea  class="form-control align-middle"
                                                name="work-place" id="work-place" cols="25" rows="4"
                                                v-model="fields['Место работы учителя']"
                                            ></textarea>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </slot>
                    </div>
                    <div class="modal-footer bg-light p-2">
                        <slot name="footer"></slot>
                        <button type="button" class="btn btn-secondary m-0" data-dismiss="modal" @click="close">Отмена</button>
                        <button v-if="modalRole === 1" type="button" class="btn btn-success" @click="update">Применить</button>
                        <button v-else-if="modalRole === 2" type="button" class="btn btn-primary" @click="create">Сохранить</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "ProtocolImportModal",
        props: {
            data: {
                type: Object,
                required: false,
                default: {},
            },
            technologyDirections: {
              type: Object,
              required: false,
              default: {}
            },
            defaultValue: {
                type: String,
                required: false,
                default: ""
            },
            title: {
                type: String,
                default: null
            },
            footer: {
                default: "* - обязательные поля"
            },

            modalRole: {
                type: Number,
                required: true,
                default: 0
            },

            clickCount: {
                type: Number,
                required: true,
                default: 0
            },

            olympiad: {
              type: Object,
              required: false,
              default: {}
            }

        },

        data: function () {
            return {
                directionType: '',
                errors: [],
                fields : {},
                isObjEdited: false,
                invalidValueClass: 'wrong-value-in-field',
                regExp: /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])/, // 2020-01-25
                regExpRu: /([0-9]|0[1-9]|[1-2][0-9]|3[0-1]).([0-9]|0[1-9]|1[0-2]).[0-9]{4}/ // 21.12.2021
            }
        },

        methods: {

            create() {
                this.updateObjectEntries();
                this.checkForm();
                if (this.errors.length === 0) {
                this.$emit('create');
                this.fields = {};
                }
            },

            close() {
                this.fields = {};
                this.$emit('close');
            },

            update() {
                this.updateObjectEntries();
                this.checkForm();
                if (this.errors.length === 0) {
                    this.$emit('update');
                    this.fields =  {};
                }
            },

            updateObjectEntries() {
                this.checkifObjEdited(this.data, this.fields);
                if (this.isObjEdited) {
                    for (let key in this.fields) {
                        this.data[key] = this.fields[key];
                    }
                    let date = this.data['Дата рождения'];
                    if (date.match(this.regExp)) {
                        this.data['Дата рождения'] = moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');
                    }
                    this.data.isEdited = this.isObjEdited;
                }
            },

            checkifObjEdited(a, b) {
                for (let key in a) {
                    this.isObjEdited = a[key] !== b[key]
                    if (this.isObjEdited) return;
                }
            },

            checkForm() {
                this.errors = [];

                const formData = [
                    {id: 'snils', index: 'Снилс', errorText: 'Требуется указать СНИЛС.'},
                    {id: 'surname', index: 'Фамилия', errorText: 'Требуется указать фамилию.'},
                    {id: 'name', index: 'Имя', errorText: 'Требуется указать имя.'},
                    {id: 'birth-date', index: 'Дата рождения', errorText: 'Требуется указать дату рождения.'},
                    {id: 'gender', index: 'Пол', errorText: 'Требуется указать пол.'},
                    {id: 'edu_grade', index: 'Класс обучения', errorText: 'Требуется указать класс обучения.'},
                    {id: 'part_grade', index: 'Класс участия', errorText: 'Требуется указать класс участия.'},
                    {id: 'mark', index: 'Балл', errorText: 'Требуется указать класс балл.'},
                    {id: 'status', index: 'Статус', errorText: 'Требуется указать статус.'},
                    {id: 'last-year-prizer', index: 'Призёр муниципального этапа прошлого года', errorText: 'Призёр муниципального этапа прошлого года.'},
                ]

                formData.forEach(element => {
                    let inputField = document.getElementById(element.id);
                    if (!this.fields[element.index]) {
                    inputField.classList.add(this.invalidValueClass);
                    this.errors.push(element.errorText);
                    } else {
                        inputField.classList.contains(this.invalidValueClass) ? inputField.classList.remove(this.invalidValueClass) : false;
                    }
                });

                let inputField = document.getElementById('mark');
                let mark = this.fields['Балл'];
                mark = !!mark ? mark.toString().replace(/,/gi, '.') : 0;
                if (isNaN(mark)) {
                    inputField.classList.add(this.invalidValueClass);
                    this.errors.push('Требуется указать балл - числовое значение.');
                } else {
                    inputField.classList.contains(this.invalidValueClass) ? inputField.classList.remove(this.invalidValueClass) : false;
                }
            },

            removeClassFromInputFields(element, className) {
                let fields = document.querySelectorAll(element);
                fields.forEach(
                    element => element.classList.contains(className) ?
                    element.classList.remove(className) : false
                );
            },

            setDirectionType() {
              this.directionType = this.fields['Направление'];
            },
        },

        watch: {
            clickCount(val) {
                this.removeClassFromInputFields('input', this.invalidValueClass);
                this.removeClassFromInputFields('select', this.invalidValueClass);
                this.errors = [];
                if (this.modalRole === 1 && !!this.data) {
                    for (const [key, value] of Object.entries(this.data)) {
                        this.fields[key] = value;
                        if (!!value && (key === "Балл" || key === "Максимальный балл")) {
                            this.fields[key] = value.toString().replace('.', ',')
                        } else {
                            this.fields[key] = value;
                        }
                    }
                } else {
                    for (const [key, value] of Object.entries(this.data)) {
                        this.fields[key] = value;
                    }
                }
            },

            directionType(val) {
              this.fields['Напрвление'] = val;
            },

        },

    }
</script>

<style scoped>
    th {
        width: 200px;
    }
</style>
