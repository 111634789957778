<template>
    <div class="container">
        <div id="mute"></div>
        <!--Accordion wrapper-->
        <div class="accordion md-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
            <!-- Accordion card Governments -->
            <div class="card">
                <!-- Card header -->
                <div class="card-header" role="tab" id="heading1" @click.self="purgeSchoolData()">
                    <a data-toggle="collapse" data-parent="#accordionEx" href="#collapse1" aria-expanded="true"
                       aria-controls="collapse1">
                        <h4 class="text-center mb-0" id="govsList">
                            Районы Республики Татарстан <i class="fas fa-angle-down rotate-icon"></i>
                        </h4>
                    </a>
                </div>
                <!-- Card body -->
                <div id="collapse1" class="collapse show" role="tabpanel" aria-labelledby="heading1"
                     data-parent="#accordionEx">
                    <div class="card-body">
                        <div class="row col-md-12 m-auto" id="not_kazan">
                            <section
                                    class="col-md-3 btn btn-outline-dark text-left p-1"
                                    v-for="(item, index) in govLinks"
                                     v-show="index<=43"
                                     :key="index" @click.self="getSchoolLinks($event)"
                                     :data-id="index"
                            >
                                {{index+1}}. {{item.name}}
                            </section>
                        </div>
                        <h4 class="m-md-2 text-md-center">г.Казань</h4>
                        <div class="row col-md-12 m-auto" id="kazan">
                            <section
                                class="col-md-3 btn btn-outline-dark text-left p-1"
                                v-for="(item, index) in govLinks" ref="index" v-show="index>43"
                                :key="index" @click.self="getSchoolLinks($event)"
                                :data-id="index"
                            >
                                {{index+1}}. {{item.name}}
                            </section>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Accordion card Schools-->
            <div v-if="schoolLinks.length>0" class="card">
                <!-- Card header -->
                <div class="card-header" role="tab" id="heading2" @click="purgeSchoolData()">
                    <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapse2"
                       aria-expanded="false" aria-controls="collapse2">
                        <h4 v-if="schoolLinks.length>0 && selectedGovName.indexOf('абережные', 1)===-1"
                            class="text-center mb-0" id="schoolList">
                            {{selectedGovName}} район (Школы) <i class="fas fa-angle-down rotate-icon"></i>
                        </h4>
                        <h4 v-else class="text-center mb-0">{{selectedGovName}} (Школы)</h4>
                    </a>
                </div>
                <!-- Card body-->
                <div id="collapse2" class="collapse" role="tabpanel" aria-labelledby="heading2" data-parent="#accordionEx">
                    <div class="card-body p-3">
                        <div class="text-center">
                            <button class="btn btn-primary mb-3" id="allSchoolsData" @click="allSchoolsData(1)">
                                Собрать данные
                            </button>
                            <button v-if="schoolData.length >= 1" class="btn btn-success mb-3"
                                    id="allToExcel" @click="saveFile(schoolData)">
                                Скачать Excel
                            </button>
                        </div>
                        <div v-if="schoolLinks.length>0" class="row col-md-12 m-auto" id="schools">
                            <section
                                class="col-md-6 btn btn-outline-dark text-left p-2" id="thisSchool"
                                v-for="(item, index) in schoolLinks"
                                :key="'sch' + index"
                                @click.self="singleSchoolData($event, 1)" :data-id="index">
                                {{index+1}}. {{item.name}}
                            </section>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Accordion card PreSchool-->
            <div v-if="preSchoolLinks.length>0" class="card">
                <!-- Card header -->
                <div class="card-header" role="tab" id="heading3" @click="purgeSchoolData()">
                    <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapse3"
                       aria-expanded="false" aria-controls="collapse3">
                        <h4 v-if="preSchoolLinks.length>0 && selectedGovName.indexOf('абережные', 1)===-1"
                            class="text-center mb-0" id="preSchoolList">
                            {{selectedGovName}} район (Детские сады) <i class="fas fa-angle-down rotate-icon"></i>
                        </h4>
                        <h4 v-else class="text-center mb-0">{{selectedGovName}} (Детские сады)</h4>
                    </a>
                </div>
                <!-- Card body -->
                <div id="collapse3" class="collapse" role="tabpanel" aria-labelledby="heading2" data-parent="#accordionEx">
                    <div class="card-body p-3">
                        <div class="text-center">
                            <button class="btn btn-primary mb-3" id="allPreSchoolsData" @click="allSchoolsData(4)">
                                Собрать данные
                            </button>
                            <button v-if="schoolData.length >=1" class="btn btn-success mb-3"
                                    id="allPreToExcel" @click="saveFile(schoolData)">
                                Скачать Excel
                            </button>
                        </div>
                        <div v-if="preSchoolLinks.length>0" class="row col-md-12 m-auto" id="preschools">
                            <section
                                class="col-md-6 btn btn-outline-dark text-left p-2" id="thisPreSchool"
                                v-for="(item, index) in preSchoolLinks"
                                :key="'pre' + index"
                                @click.self="singleSchoolData($event, 4)" :data-id="index"
                            >
                                {{index+1}}. {{item.name}}
                            </section>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Accordion card DopEdu -->
            <div v-if="dopEduLinks.length>0" class="card">
                <!-- Card header -->
                <div class="card-header" role="tab" id="heading4" @click="purgeSchoolData()">
                    <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapse4"
                       aria-expanded="false" aria-controls="collapse4">
                        <h4 v-if="dopEduLinks.length>0 && selectedGovName.indexOf('абережные', 1)===-1"
                            class="text-center mb-0" id="dopEduList">
                            {{selectedGovName}} район (Дополнительное образование) <i class="fas fa-angle-down rotate-icon"></i>
                        </h4>
                        <h4 v-else class="text-center mb-0">{{selectedGovName}} (Дополнительное образование)</h4>
                    </a>
                </div>
                <!-- Card body -->
                <div id="collapse4" class="collapse" role="tabpanel" aria-labelledby="heading2" data-parent="#accordionEx">
                    <div class="card-body p-3">
                        <div class="text-center">
                            <button class="btn btn-primary mb-3" id="allDopEduData" @click="allSchoolsData(5)">
                                Собрать данные
                            </button>
                            <button v-if="schoolData.length >=1" class="btn btn-success mb-3"
                                    id="allDopToExcel" @click="saveFile(schoolData)">
                                Скачать Excel
                            </button>
                        </div>
                        <div v-if="dopEduLinks.length>0" class="row col-md-12 m-auto" id="dopEdu">
                            <section
                                class="col-md-6 btn btn-outline-dark text-left p-2" id="thisDopEdu"
                                v-for="(item, index) in dopEduLinks"
                                :key="'dop' + index"
                                @click.self="singleSchoolData($event, 5)" :data-id="index"
                            >
                                {{index+1}}. {{item.name}}
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Accordion wrapper -->

        <div class="col" id="tempData">
            <div class="row" id="typeGov" hidden>0</div>
            <div class="row" id="typeSchool" hidden>1</div>
            <div class="row" id="typePreSchool" hidden>4</div>
            <div class="row" id="typeDopEdu" hidden>5</div>
        </div>

        <button
            id="modalButton"
            type="button"
            class="btn"
            @click="showModal"
            hidden
        >
            Open Modal
        </button>
        <modal
            v-show="isModalVisible"
            :data = "singleSchData"
            :title="modalTitle"
            @close="closeModal"
            @saveFile="saveFile(singleSchData)"
        />

    </div>
</template>

<script>
    import modal from './ModalForm.vue';
    export default {
        components: { modal },
        data: function () {
            return {
                mute: false,
                showButton: false,
                govLinks: [],
                schoolLinks: [],
                preSchoolLinks: [],
                dopEduLinks: [],
                schoolData: [],
                singleSchData: [],
                showItems: false,
                seconds: 0,
                timeLimit: 10,
                EDU_TATAR_RU: 'https://edu.tatar.ru',
                selectedGovName: '',
                selectedGovIndex: null,
                isModalVisible: false,
                modalTitle: "Данные учреждения"
            }
        },
        methods: {
            async loadData() {
                this.mute = true;
                await fetch('/api/index-data')
                .then(res => res.json())
                .then(json => {
                    document.getElementById('typeGov').innerHTML = json
                })
                .catch(error => {});
                this.getGovList();
                document.getElementById('typeGov').innerHTML = '';
                this.mute = false;
            },

            getGovList() {
                this.govLinks = [];
                let links = [];
                links = document.querySelectorAll('ul.edu-list > li > a');
                for (let item of links) {
                    this.govLinks.push({url: item.getAttribute("href"), name: item.innerText})
                }
            },

            async getSchoolLinks(event) {
                let itemIndex = event.currentTarget.dataset.id;
                let url = {};
                url.school = this.EDU_TATAR_RU + this.govLinks[itemIndex].url + '/type/1';
                url.preSchool = this.EDU_TATAR_RU + this.govLinks[itemIndex].url + '/type/4';
                url.dopEdu = this.EDU_TATAR_RU + this.govLinks[itemIndex].url + '/type/5';
                this.selectedGovName = this.govLinks[itemIndex].name;
                this.selectedGovIndex = itemIndex;
                this.mute = true;

                await fetch('/api/school-list?url=' + url.school)
                .then(res => res.json())
                .then(json => {document.getElementById('typeSchool').innerHTML = json})
                .catch(error => {});

                await fetch('/api/school-list?url=' + url.preSchool)
                .then(res => res.json())
                .then(json => {document.getElementById('typePreSchool').innerHTML = json})
                .catch(error => {});

                await fetch('/api/school-list?url=' + url.dopEdu)
                .then(res => res.json())
                .then(json => {document.getElementById('typeDopEdu').innerHTML = json})
                .catch(error => {});

                this.getSchoolList();
                $('#govsList').trigger('click');
                this.mute = false;
            },

            getSchoolList() {
                this.schoolLinks = [];
                let links = {};
                links.typeSchool = document.querySelectorAll('#typeSchool ul.edu-list > li > a');
                links.typePreSchool = document.querySelectorAll('#typePreSchool ul.edu-list > li > a');
                links.typeDopEdu = document.querySelectorAll('#typeDopEdu ul.edu-list > li > a');
                for (let item of links.typeSchool) {
                    this.schoolLinks.push({url: item.getAttribute("href"), name: item.innerText, type: 1})
                }

                for (let item of links.typePreSchool) {
                    this.preSchoolLinks.push({url: item.getAttribute("href"), name: item.innerText, type: 4})
                }
                for (let item of links.typeDopEdu) {
                    this.dopEduLinks.push({url: item.getAttribute("href"), name: item.innerText, type: 5})
                }
            },

            getSchoolItems(allSchools = true) {
                let fullName = document.querySelectorAll('#content > h2') ? document.querySelectorAll('#content > h2') : '';
                let otherData = document.querySelectorAll('td') ? document.querySelectorAll('td') : '';
                let schData = {};
                schData["Район"] = this.selectedGovName;
                schData["Полное название"] = fullName[0].textContent;
                for (let n = 1; n <= otherData.length - 1; n += 2) {
                    let firsColTd = otherData[n - 1];
                    firsColTd.innerText = firsColTd.innerText.replace(':', '');
                    let secondColTd = otherData[n];
                    schData[firsColTd.innerText] = secondColTd.innerText;
                }
                if (allSchools) {
                    this.schoolData.push(schData);
                } else {
                    this.singleSchData = [];
                    this.singleSchData.push(schData);
                }
            },

            async allSchoolsData(eduType) {
                this.mute = true;
                this.schoolData = [];
                let eduLinks;
                switch (eduType) {
                    case 1: eduLinks = this.schoolLinks;
                    break;
                    case 4: eduLinks = this.preSchoolLinks;
                    break;
                    case 5: eduLinks = this.dopEduLinks;
                    break;
                }
                let counter = eduLinks.length;
                for (let item of eduLinks) {
                    let url = this.EDU_TATAR_RU + item.url;
                    await fetch('/api/school-data?url=' + url)
                    .then(res => res.json())
                    .then(json => {document.getElementById('typeSchool').innerHTML = json})
                    .catch(error => {});

                    this.getSchoolItems();
                    counter--;
                    document.getElementById('mute').innerHTML = "<h2 class='font-weight-bold'>" + counter.toString() +"</h2>";
                }
                this.json_data = JSON.parse(JSON.stringify(this.schoolData));
                this.mute = false;
                document.getElementById('typeSchool').innerHTML = '';

                document.getElementById('typePreSchool').innerHTML = '';
                document.getElementById('typeDopEdu').innerHTML = '';
            },

            async singleSchoolData(event, eduType){
                this.mute = true;
                this.singleSchData = [];
                let eduLinks;
                switch (eduType) {
                    case 1: eduLinks = this.schoolLinks;
                    break;
                    case 4: eduLinks = this.preSchoolLinks;
                    break;
                    case 5: eduLinks = this.dopEduLinks;
                    break;
                }
                let itemIndex = event.currentTarget.dataset.id;
                let url = this.EDU_TATAR_RU + eduLinks[itemIndex].url;
                await fetch('/api/school-data?url=' + url)
                    .then(res => res.json())
                    .then(json => {document.getElementById('typeSchool').innerHTML = json})
                    .catch(error => {});

                this.getSchoolItems(false);
                this.singleSchData[0]["Ссылка на сайт"] = url;
                this.mute = false;
                this.isModalVisible = true;
                document.getElementById('typeSchool').innerHTML = '';

                document.getElementById('typePreSchool').innerHTML = '';
                document.getElementById('typeDopEdu').innerHTML = '';
            },

            showModal() {
                this.isModalVisible = true;
            },

            closeModal() {
                this.isModalVisible = false;
            },

            saveFile(data) {
                let opts = [{sheetid: 'Данные школ', header: true}];
                let res = alasql('SELECT * INTO XLSX("schoolData.xlsx",?) FROM ?', [opts, [data]]);
            },

            purgeSchoolData() {
                this.schoolData = [];
            },

        },

        watch: {
            mute(val) {
                document.getElementById('mute').className = val ? "on" : "";
                document.getElementById('mute').innerHTML = val ?
                "<h2>Дождитесь завершения процесса ...</h2>" : "";
            },
        },

        created() {
            this.loadData()
        }
    }
</script>

<style scoped>
</style>
