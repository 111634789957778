<template>
    <div>
        <div class="modal-backdrop">
            <div class="modal modal-dialog-scrollable modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-light p-3">
                        <slot name="header">
                            <h5 class="modal-title">{{title}}</h5>
                            <button type="button" class="close noHover p-0 m-0" data-dismiss="modal" aria-label="Close" @click="close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </slot>
                    </div>
                    <div class="modal-body">
                        <slot name="body">
                            <table class="table table-striped table-bordered table-hover">
                                <tbody>
                                    <tr v-for="(value, key) in data[0]" :key="key">
                                        <th>{{key}}</th>
                                        <td v-if="key !== 'Ссылка на сайт'">{{value}}</td>
                                        <td v-else><a v-bind:href="`${value}`">Перейти на сайт школы</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </slot>
                    </div>
                    <div class="modal-footer bg-light p-2">
                        <slot name="footer">
                            <button type="button" class="btn btn-secondary m-0" data-dismiss="modal" @click="close">Close</button>
                            <button type="button" class="btn btn-primary" v-if="data.length>0" @click="saveToExcel">Скачать Excel</button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "ModalForm",
        props: {
            data: {
                type: Array,
                required: false,
                default: []
            },
            defaultValue: {
                type: String,
                required: false,
                default: ""
            },
            title: {
                type: String,
                default: null
            },
            footer: {
                default: null
            },
        },

        methods: {
            close() {
                this.$emit('close');
            },

            saveToExcel () {
                this.$emit('saveFile');
            },
        },
    }
</script>

<style scoped>
</style>
