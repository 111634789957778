/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

//require('./bootstrap');

// window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

Vue.component('protocol-school-import', require('./components/ProtocolSchoolImport.vue').default);
// Vue.component('protocol-school-export', require('./components/ProtocolSchoolExport.vue').default);
Vue.component('protocol-school-export-watch', require('./components/ProtocolSchoolExportWatch.vue').default);
Vue.component('protocol-school-import-modal', require('./components/ProtocolSchoolImportModal.vue').default);
Vue.component('protocol-municipal-import', require('./components/ProtocolImportMunicipal.vue').default);
Vue.component('protocol-municipal-export', require('./components/ProtocolMunicipalExport.vue').default);
Vue.component('protocol-municipal-import-modal', require('./components/ProtocolImportMunicipalModal.vue').default);
Vue.component('school-profile', require('./components/SchoolProfile').default);
Vue.component('site-data', require('./components/SiteData.vue').default);
Vue.component('modal-form', require('./components/ModalForm.vue').default);
Vue.component('school-responsible', require('./components/SchoolResponsible.vue').default);
Vue.component('edudepartment-responsibles', require('./components/EduDepartmentResponsibles.vue').default);

// Vue.component('students-list', require('./components/StudentsList.vue').default);
// Vue.component('student-modal', require('./components/StudentModal.vue').default);
// Vue.component('admin-students-list', require('./components/AdminStudentsList.vue').default);

const app = new Vue({
    el: '#app',
});
