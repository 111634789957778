<template>
   <div class="container container-fluid">
        <div id="mute"></div>
        <div class="alert alert-danger" :class="isSaved ? 'd-none' : 'd-blok'" id="alert-not-saved">
           <span>Данные еще не отправлены на сервер!<br>Не забудьте нажать кнопку "Отправить"!</span>
        </div>
        <p class="text-center">
            <a href="/files/protocol_SCHOOL.xlsx" download>
                <i class="fas fa-file-excel"></i>
                Скачать шаблон протокола
                <i class="fas fa-file-excel"></i>
            </a>
        </p>
        <div class="d-flex justify-content-between align-items-center">
            <select class="form-control border bg-white" id="showsubject" @change="selectSubject()" v-model="olympiadId">
                <option :value="null" disabled selected hidden>Выберите олимпиаду</option>
                <option v-for="subject in subjects" v-bind:value = "subject.olympiad_id" :key="subject.name + subject.olympiad_date">{{ dateFormat(subject.olympiad_date) }} - {{ subject.name }}</option>
            </select>
            <button class="btn btn-outline-secondary ml-1 date-limit" title="Добавить новую запись" @click="showModal()" :disabled="!olympiadId || !isSaved || olympiad.isOlympiadFinished"><i class="fas fa-plus"></i> Добавить нового участника</button>
            <button class="btn btn-outline-secondary ml-1" title="Выгрузка сохранённого протокола" @click="saveToExcel()" :disabled="dbData.length === 0"><i class="fas fa-download"></i> Выгрузить протокол</button>
        </div>

       <div class="d-flex justify-content-center">
           <div class="row align-items-center mt-3 px-3 date-limit" id="list-upload">
               <label for="file-input"></label>
                <input
                    type="file" id="file-input" ref="file" accept=".xls, .xlsx, .ods"
                    @click="setDefaults"
                    :class="{ ' is-invalid' : error.message }"
                    :disabled="!olympiadId || dbData.length  > 0 || olympiad.isOlympiadFinished"
                />

                <div class="m-0 p-0 ml-2" :hidden="hidden">
                    <button
                        class="btn btn-outline-secondary"
                        :hidden="!hideSave"
                        @click="readData"
                    >
                        Открыть
                    </button>
                    <button
                        class="btn btn-outline-success" id="submit"
                        :hidden="hideSave"
                        @click="store(fileData)"
                    >
                        Отпарвить
                    </button>
                    <button
                        class="btn btn-outline-danger" id="cancel"
                        @click="cancelImport"
                    >
                        Отмена
                    </button>
                </div>
               <div v-if="error.message" class="invalid-feedback"></div>
           </div>
       </div>
        <hr>
       <div class="table-container">
           <div class="d-flex justify-content-between mx-4">
                <label class="m-0">Показать по
                <select class="rounded bg-white" name="per_page" v-model.number="pageSize" @change="onPageSizeChange" v-bind:value="pageSize">
                    <option selected hidden>{{pageSize}}</option>
                    <option v-for="option in options" :key="option">{{option}}</option>
                </select>
                </label>
                <div>
                    Показать данные учителя
                    <input type="checkbox" name="teacher-info" id="teacher-info" v-model="showTeacher">
                </div>
                <div class="align-middle m-0 pt-1 float-right" v-if="total" >Всего: {{total}}</div>

                <div class="align-middle m-0 pt-1 float-right" v-else>Всего: 0 </div>
           </div>
           <table class="table table-bordered table-striped table-hover table-responsive-lg mt-3 mb-3">
                <thead>
                <tr>
                    <th class="align-middle text-center" v-for="(item, index) in headerValues" :key="'th' + index"
                        :hidden="[13, 14].includes(index) ? !showTeacher : [1, 2].includes(index) ? !showTechnology : false"
                    >
                        {{ item }}
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in pageData" :key="'tr' + index">
                    <td class="align-middle text-center row-num">{{ index + 1 + pageSize * (current_page - 1) }}</td>
                    <td class="align-middle" v-show="showTechnology" :class="funcslib.isStringInArray(item['Направление'], Object.keys(funcslib.getTechnologyDirections()))">{{item['Направление']}}</td>
                    <td class="align-middle" v-show="showTechnology" :class="funcslib.isStringInArray(item['Виды практик'], funcslib.getTechnologyDirections()[item['Направление']])">{{item['Виды практик']}}</td>
                    <td class="align-middle" :class="!item['Снилс'] ? 'empty_cell' : ''">{{item['Снилс']}}</td>
                    <td class="align-middle" :class="!item['Фамилия'] || !item['Имя'] ? 'empty_cell' : ''">{{item['Фамилия']}} {{item['Имя']}} {{item['Отчество'] !== '-' ? item['Отчество'] : ''}}</td>
                    <td class="align-middle text-center" :class="funcslib.isDateCorrect(item['Дата рождения'])">{{item['Дата рождения']}}</td>
                    <td class="align-middle text-center" :class="funcslib.checkGender(item['Пол'])">{{item['Пол']}}</td>
                    <td class="align-middle text-center">{{item['Участник с ОВЗ (да/нет)']}}</td>
                    <td class="align-middle text-center" :class="funcslib.isGradeNumeric(item['Класс обучения'])">{{item['Класс обучения']}}</td>
                    <td class="align-middle text-center" :class="funcslib.isGradeNumeric(item['Класс участия'])">{{item['Класс участия']}}</td>
                    <td class="align-middle text-center" :class="funcslib.isMarkNumeric(item['Балл'])">{{funcslib.showNumericValue(item['Балл'])}}</td>
                    <td class="align-middle text-center">{{item['Максимальный балл']}}</td>
                    <td class="align-middle text-center" :class="!item['Статус'] ? 'empty_cell_bg' : ''">{{item['Статус']}}</td>
                    <td class="align-middle text-center" :class="!item['Призёр муниципального этапа прошлого года'] ? 'empty_cell' : ''">{{item['Призёр муниципального этапа прошлого года']}}</td>
                    <td class="align-middle" :class="!item['ФИО учителя'] ? 'empty_cell' : ''" v-show="showTeacher">{{item['ФИО учителя']}}</td>
                    <td class="align-middle" :class="!item['Место работы учителя'] ? 'empty_cell' : ''" style="word-break:break-all;" v-show="showTeacher">{{item['Место работы учителя']}}</td>
                    <td class="align-middle text-center">
                        <button class="btn btn-outline-success mb-1 date-limit" id="showModal" type="button" title="Изменить" @click="showModal(index)"><i class="fas fa-edit"></i></button>
                        <button class="btn btn-outline-danger date-limit" id="delete" title="Удалить" @click="deleteTableRow(index)"><i class="far fa-trash-alt"></i></button>
                    </td>
                </tr>
                <tr v-if="!!olympiadId">
                    <td colspan="16">
                        <button class="btn btn-outline-primary m-1 add-record date-limit" title="Добавить новую запись" @click="showModal()" :disabled="!isSaved || olympiad.isOlympiadFinished"><i class="fas fa-plus"></i></button>
                    </td>
                </tr>
                </tbody>
            </table>
            <div class="row mb-2" id="gridFooter" v-show="pageData.length > 0">
                <div class="col-md d-flex justify-content-between align-items-center px-5">
                    <div class="">Страница {{ current_page }} из {{ pages[pages.length - 1] }} </div>
                    <nav aria-label="Page navigation">
                        <ul class="pagination pagination-sm m-0">
                            <li class="page-item" @click="paginate(fileData, pageSize, 1)"><a class="page-link"> &lt;&lt; </a></li>
                            <li class="page-item">
                                <a class="page-link" @click="previousPage"> &lt; </a>
                            </li>
                            <li class="page-item" v-for="page in pages"
                                v-show="page > current_page - 4 && page < current_page + 4"
                                v-bind:class="{'active': checkPage(page)}"
                                :key="page"
                            >
                                <a class="page-link" @click="paginate(fileData, pageSize, page)">{{ page }}</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" @click="nextPage"> &gt; </a>
                            </li>
                            <li class="page-item" @click="paginate(fileData, pageSize, pages[pages.length - 1])"><a class="page-link"> &gt;&gt; </a></li>
                        </ul>
                    </nav>
                </div>
            </div>
            <div class="alert alert-warning date-limit mt-5" v-show="dbData.length > 0">
                <button class="btn btn-outline-danger mx-1" title="Полное удаление сохранённого протокола" @click="deleteWholeProtocol()">
                    Удалить протокол из системы.
                </button>
                <span>Внимание!!! Выполните данное действие, только при необходимости полной замены протокола по предмету.</span>
            </div>
       </div>
       <section v-if="errored" class="alert alert-danger alert-dismissible alert-important mt-3" role="alert" id="failed_alert">
            <span id="failed_text">К сожалению, в данный момент мы не можем получить эту информацию, пожалуйста, попробуйте вернутьсяпозже.</span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true" style="color: red; font-weight: 600;">&times;</span>
            </button>
        </section>

        <section class="alert alert-success alert-dismissible alert-important mt-3" role="alert" id="success_alert">
            <span id="success_text"></span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true" style="color: red; font-weight: 600;">&times;</span>
            </button>
        </section>

        <protocol-school-import-modal
            v-show="isModalVisible"
            :data = "selectedPageData"
            :title="modalTitle"
            :modalRole="modalRole"
            :clickCount="clickCount"
            :olympiad="olympiad"
            :technologyDirections="funcslib.getTechnologyDirections()"
            @close="closeModal"
            @update="editTableRow"
            @create="store([selectedPageData])"
        />
   </div>
</template>
<script>
    import funcslib from './common';
    export default {
        props: {
            muteState: false,
        },
        data() {
            return {
                areaName: '',
                clickCount: 0,
                disableSave: true,
                dbData: [],
                error: {},
                errored: false,
                fileData: [],
                funcslib: funcslib,
                headerValues: [
                    "№",
                    "Направление",
                    "Виды практик",
                    "Снилс",
                    "Фамилия Имя Отчество",
                    "Дата рождения",
                    "Пол",
                    "Участник с ОВЗ (да/нет)",
                    "Класс обучения",
                    "Класс участия",
                    "Балл",
                    "Максимальный балл",
                    "Статус",
                    "Призёр муниципального этапа прошлого года",
                    "ФИО учителя",
                    "Место работы учителя",
                    " "
                ],
                hidden: true,
                hideSave: true,
                isDbRecord: false,
                isModalVisible: false,
                isOlympiadActive: false,
                isSaved: true,
                minutes: 0,
                modalRole: 0,
                modalTitle: '',
                mute: false,
                olympiad: {"name": ''},
                olympiadId : null,
                olympiadIsActive: false,
                rowIndex: null,
                selectedPageData: {},
                subjects: [],
                showTeacher: true,
                showTechnology: false,
                user: null,
                current_page: 1,
                options: [5, 10, 20, 30, 50, 100],
                pageData: [],
                pages: [],
                pageSize: 15,
                schoolName: '',
                token: null,
                total: null,
            }
        },
        methods: {
             selectSubject() {
                this.cancelImport();
                this.getDbData();
                this.olympiad = this.subjects.find(item => item.olympiad_id === this.olympiadId);
                // this.getOlympiadStateFromServer();

                if (!!this.olympiad) {
                    document.getElementById('olympsubject').innerText = this.olympiad.name + " (Дата проведения: " + this.dateFormat(this.olympiad.olympiad_date) + ". Протокол заполнить до " + moment(this.olympiad.appeal_end, 'YYYY-MM-DD HH:mm').format('HH:mm') + " часов " + this.dateFormat(this.olympiad.appeal_end) + ")";
                    document.getElementById('olympiad_info').classList.add('d-block');
                } else {
                    document.getElementById('olympiad_info').classList.add('d-none');
                }
            },

            async getDbData() {
                let protocolData = null;
                this.fileData = [];
                this.mute = true;
                await fetch(`../api/get-protocol?olympiad_id=${this.olympiadId}`)
                .then(res => res.json())
                .then(json => protocolData = json)
                .catch(error => {
                    this.errored = true;
                });
                this.dbData = protocolData['dataRows'];
                this.areaName = protocolData['areaName'];
                this.schoolName = protocolData['schoolName'];
                protocolData = null;
                this.mute = false;
                this.prepareDataToShow(this.fileData);
                this.resetPageNumbers();
                this.paginate(this.fileData, this.pageSize, 1);
            },

            readFileAsync(file) {
                return new Promise((resolve, reject) => {
                    let reader = new FileReader();
                    reader.onload = () => {
                        resolve(reader.result);
                    };
                    reader.onerror = reject;
                    reader.readAsBinaryString(file); //readAsArrayBuffer(file);
                })
            },

            arrayBufferToString(arrayBuffer) {
                let data = new Uint8Array(arrayBuffer);
                let workbook = XLSX.read(data, {type: 'array'});
                let sheetName = workbook.SheetNames[0];
                let worksheet = workbook.Sheets[sheetName];
                worksheet = XLSX.utils.sheet_to_json(worksheet);
                let i = 0;
                worksheet.forEach(obj => {
                    let tmpObj = {
                        '№': i, 'Направление': '', 'Виды практик' :'', 'Снилс' : '', 'Фамилия': '', 'Имя': '', 'Отчество': '-', 'Дата рождения': '',
                        'Пол': '', 'Участник с ОВЗ (да/нет)': 'нет', 'Класс обучения': null, 'Класс участия': null,
                        'Балл': null, 'Максимальный балл': null, 'Статус': '',
                        'Призёр муниципального этапа прошлого года': 'нет',
                        'ФИО учителя': '', 'Место работы учителя': '',
                        isEdited: false, 'Код ОО': this.user.school_code
                    };
                    i++;

                    for (const [key, value] of Object.entries(obj)) {
                        if (typeof value === 'string') {
                            Object.defineProperty(obj, key, {
                                value: value.trim().replace('\n', ' ').replace('  ', ' '),
                            });
                        }

                        if (key === 'Снилс' && !!obj[key]) {
                          obj[key] = obj[key].replace(' - ', '').replace('-', '');
                        }

                        if (key === 'Пол' && !!obj[key]) {
                            obj[key] = obj[key].toString().toLowerCase();
                        }
                        if (typeof value === 'number') {
                            Object.defineProperty(obj, key, {
                                value: value.toString().replace('.', ','),
                            });
                        }
                        if (key === 'Дата рождения') {
                            if (typeof value === 'number') {
                                let birthDate = new Date(Math.round((value - 25569)*86400*1000));
                                let options = {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit"
                                };
                                Object.defineProperty(obj, key, {
                                    value: birthDate.toLocaleDateString("ru", options),
                                });
                            }
                        }
                        if (tmpObj.hasOwnProperty(key)) tmpObj[key] = obj[key];
                    }
                   this.fileData.push(tmpObj);
                });
                worksheet = [];
                this.total = this.fileData.length;
                this.resetPageNumbers();
                this.paginate(this.fileData, this.pageSize, 1);
                this.isSaved = false;
            },

            async processFile(file) {
                const timer = ms => new Promise(res => setTimeout(res, ms));
                this.mute = true;
                await timer(1500);
                try {
                    let arrayBuffer = await this.readFileAsync(file);
                    this.arrayBufferToString(arrayBuffer);
                } catch (error) {}
                this.mute = false;
            },

            async readData() {
                this.selectedPageData = {};
                if (!this.olympiad.isOlympiadFinished) {
                    let file = document.getElementById('file-input');
                    await this.processFile(file.files[0]);
                    this.hideSave = false;
                } else {
                    alert(`Предмет закрыт ${this.olympiad.appeal_end} ! \n\nВы можете выгрузить протокол.\n\nЗагрузка, редактирование не активно.`);
                }
            },

            cancelImport() {
                this.fileData = [];
                this.pageData = [];
                this.hideSave = true;
                this.hidden = true;
                this.isSaved = true;
                this.total = 0;
                document.getElementById('file-input').value = '';
            },

            deleteTableRow(i) {
                if (!this.olympiad.isOlympiadFinished) {
                    !!this.pageData[i].id ? this.deleteDbRecord(this.pageData[i].id) : false;
                    this.fileData.splice(i - (this.current_page - 1) * this.pageSize, 1);
                    this.pageData.splice(i, 1);
                    if (this.pageData.length === 0 && this.current_page > 1) {
                        this.current_page--;
                    }

                    if (this.fileData.length === 0) this.isSaved = true;

                    this.paginate(this.fileData, this.pageSize, this.current_page);
                    this.resetPageNumbers();
                } else {
                    alert(`Предмет закрыт ${this.olympiad.appeal_end} ! \n\nВы можете выгрузить протокол.\n\nЗагрузка, редактирование не активно.`);
                }
            },

            async deleteDbRecord(id) {
                let currentPage = this.current_page;
                if (!this.olympiad.isOlympiadFinished) {
                    this.mute = true;
                    await fetch('../api/delete-protocol', {
                        headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json, text-plain, */*",
                        "X-Requested-With": "XMLHttpRequest",
                        "X-CSRF-TOKEN": this.token
                        },
                        method: 'delete',
                        credentials: "same-origin",
                        body: JSON.stringify({id: id}) })
                    .then((data) => {
                        document.getElementById("success_alert").style.display = "block";
                        document.getElementById("success_text").innerText = 'Запись успешно удалена из базы данных';
                        this.timer();
                        if (this.fileData.length === 0) this.isSaved = true;
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
                    await this.getDbData();
                    currentPage = this.pages.includes(currentPage) ? currentPage : currentPage - 1;
                    this.paginate(this.fileData, this.pageSize, currentPage);
                    this.mute = false;
                } else {
                    alert(`Предмет закрыт ${this.olympiad.appeal_end} ! \n\nВы можете выгрузить протокол.\n\nЗагрузка, редактирование не активно.`);
                }
            },

            async deleteWholeProtocol() {
                let ids = [];
                let data = {};
                this.fileData.forEach(item => {
                    if (!!item.id ) {
                        ids.push(item.id);
                    }
                });
                data = {stage: 1, olympiad_id: this.olympiadId, ids: ids};
                if (!this.olympiad.isOlympiadFinished) {
                    if (!confirm(`Внимание!!! Из базы данных будет полностью удален протокол по предмету "${!!this.olympiad ? this.olympiad.name : '... '}".`)) return;
                    this.mute = true;
                    await fetch('../api/delete-all', {
                        headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json, text-plain, */*",
                        "X-Requested-With": "XMLHttpRequest",
                        "X-CSRF-TOKEN": this.token
                        },
                        method: 'delete',
                        credentials: "same-origin",
                        body: JSON.stringify(data) })
                    .then((data) => {
                        document.getElementById("success_alert").style.display = "block";
                        document.getElementById("success_text").innerText = 'Запись успешно удалена из базы данных';
                        this.timer();
                        this.getDbData();
                        if (this.fileData.length === 0) this.isSaved = true;
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
                    this.mute = false;
                } else {
                    alert(`Предмет закрыт ${this.olympiad.appeal_end} ! \n\nВы можете выгрузить протокол.\n\nЗагрузка, редактирование не активно.`);
                }
            },

            editTableRow() {
                if (this.modalRole === 1) {
                    if (this.selectedPageData.isEdited && !!this.selectedPageData.id) {
                        const obj = this.fileData.find(obj => obj.id === this.selectedPageData.id);
                        Object.assign(obj, this.selectedPageData);
                    } else {
                       Object.assign(this.pageData[this.rowIndex], this.selectedPageData);
                    }
                } else {
                    this.fileData.push(this.selectedPageData);
                }

                if (!this.olympiad.isOlympiadFinished) {
                    !!this.pageData[this.rowIndex].id ? this.updateDbRecord() : false;
                    this.closeModal();
                } else {
                    alert(`Предмет закрыт ${this.olympiad.appeal_end} ! \n\nВы можете выгрузить протокол.\n\nЗагрузка, редактирование не активно.`);
                }
            },

            async updateDbRecord() {
                let currentPage = this.current_page;
                if (!this.olympiad.isOlympiadFinished) {
                    if ( this.selectedPageData.isEdited ) {
                        delete this.selectedPageData.isEdited;
                        Object.assign(this.pageData[this.rowIndex], this.selectedPageData);
                        let rowData = JSON.stringify({"id": this.pageData[this.rowIndex].id, "data_row": JSON.stringify(this.pageData[this.rowIndex]) });
                        await fetch('/api/update-protocol/', {
                            headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json, text-plain, */*",
                            "X-Requested-With": "XMLHttpRequest",
                            "X-CSRF-TOKEN": this.token
                            },
                            method: 'PATCH',
                            credentials: "same-origin",
                            body: rowData  })
                        .then((data) => {
                          this.isModalVisible = false;
                          document.getElementById("success_alert").style.display = "block";
                          document.getElementById("success_text").innerText = 'Запись в базе данных успешно обновлена';
                          this.timer(2);
                          this.selectedPageData = {};
                        })
                        .catch(function(error) {});
                        this.mute = false;
                        await this.getDbData();
                        this.paginate(this.fileData, this.pageSize, currentPage);
                    }
                } else {
                    alert(`\nПредмет закрыт ${this.olympiad.appeal_end}! \n\nВы можете выгрузить протокол.\n\nЗагрузка, редактирование не активно.`);
                }
            },

            onPageSizeChange() {
                this.resetPageNumbers();
                this.paginate(this.fileData, this.pageSize, 1)
            },

            setDefaults() {
                this.hideSave=true;
                this.hidden = false;
                this.errored = false;
                this.fileData = [];
                this.pageData = [];
            },

            async store(objectsArray = []) {
                const timer = ms => new Promise(res => setTimeout(res, ms));
                let emptyRowsNum = this.getRowsToRemove();
                if (emptyRowsNum.length > 0) {
                    const message = `Внимание!\nСтрок в файле с неполными или некорректными данными: ${emptyRowsNum.length}.`
                    + '\nСистема их не запишет в базу данных! '
                    + 'Обратите внимание на следуюшие столбцы: Фамилия, Имя, Дата рождения, Пол, Класс обучения, Класс обучения, Класс участия, ФИО учителя, Место работы учителя. '
                    + 'В них не должно быть пустых ячеек. "Класс обучения" и "Класс участия" - только числа от 1 до 11.'
                    + '\nЧтобы внести исправления нажмите "Отмена".\nДля сохранения нажмите "OK"';

                    if (!confirm(message)) {
                        return;
                    } else {
                        emptyRowsNum.forEach(num => {
                            this.fileData.splice(this.fileData.findIndex(x => x['№'] == num), 1);
                        });
                        this.total = this.fileData.length;
                    }
                };
                objectsArray.forEach(obj => {
                    obj.hasOwnProperty('isEdited') ? delete obj.isEdited : false;
                });
                const dataRows = this.prepareDataToSave(objectsArray);
                let dataRowLength = dataRows.length;

                if (dataRowLength <= 50) {
                    this.mute = true;
                    let data = {dataRows: dataRows, olympiad_id: this.olympiadId};
                    await this.saveToDB(data);
                    await timer(1500);
                } else {
                    let rows = [];
                    let counter = 0;
                    const loader = document.getElementById('mute');
                    if (!!loader) {
                        loader.innerHTML = `Осталось ${this.total} из ${this.total}` ;
                        loader.style.fontSize = '40px';
                        loader.style.fontWeight = '600';
                    }
                    this.mute = true;
                    for (let i = 0; i < dataRowLength; i++) {
                        rows.push(dataRows[i]);
                        if (i % 50 === 49) {

                            let data = {dataRows: rows, olympiad_id: this.olympiadId};
                            await this.saveToDB(data);
                            counter = dataRowLength - i;
                            !!loader ? loader.innerHTML = `Осталось ${counter - 1} из ${this.total}` : false;
                            rows = [];
                            await timer(1500);
                        }
                    }
                    if (counter > 0) {
                        let data = {dataRows: rows, olympiad_id: this.olympiadId};
                        await this.saveToDB(data);
                        !!loader ? loader.innerHTML = 0 : false;
                        rows = [];
                    }
                }

                document.getElementById('file-input').value = '';
                await timer(2000);
                this.mute = false;
                this.cancelImport();
                await this.getDbData();
                await timer(2000);
                !!this.selectedPageData ? this.closeModal() : false;
                this.selectedPageData = {};
                if (this.modalRole === 2) {
                    let lastPage = this.pages[this.pages.length - 1];
                    this.paginate(this.fileData, this.pageSize, lastPage);
                }
                this.isSaved = true;
            },

            async saveToDB(data) {
                await fetch('../api/save-protocol', {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-CSRF-Token": this.token
                },
                method: 'post',
                credentials: "same-origin",
                referrerPolicy: 'no-referrer',
                body: JSON.stringify({olympData: data})
                })
                .then(function(response){
                    return response.json();
                })
                .then(function(json){
                    document.getElementById('file-input').value = '';
                })
                .catch(function(error){});
            },

            getRowsToRemove() {
                let emptyRowsCount = 0;
                let rowNums = [];
                this.fileData.forEach(item => {
                    let flag = false;
                    flag = !!item['Снилс'] && !!item['Фамилия'] && !!item['Имя'] && !!item['Дата рождения']
                    && !!item['Пол'] && !!item['Класс обучения'] && !!Number(item['Класс обучения'])
                    && !!item['Класс участия']  && !!Number(item['Класс участия']) && !!item['Балл']
                    && !!item['ФИО учителя'] && !!item['Место работы учителя'];
                    if (flag === false) {
                        emptyRowsCount++;
                        rowNums.push(item['№']);
                    }
                });
                return rowNums;
            },

            prepareDataToSave(objectsArray = []) {
                let stringifiedData = [];
                if (objectsArray.length === 0) {alert('Пустой список!'); return}
                objectsArray.forEach(item => {
                    stringifiedData.push(JSON.stringify(item));
                });
                return stringifiedData;
            },

            prepareDataToShow(objectsArray = []) {
                this.dbData.forEach(item => {
                    let dataRow = JSON.parse(item.data_row);
                    let data = {
                        '№': 0, 'Снилс':'',
                        'Район': '',
                        'Предмет': '',
                        'Направление': '',
                        'Виды практик': '',
                        'Код ОО': '',
                        'Наименование ОО': '',
                        'Фамилия': '',
                        'Имя': '',
                        'Отчество': '',
                        'Дата рождения': null,
                        'Пол': '',
                        'Участник с ОВЗ (да/нет)': '',
                        'Класс обучения': null,
                        'Класс участия': null,
                        'Балл': null,
                        'Максимальный балл': null,
                        'Статус': '',
                        'Призёр муниципального этапа прошлого года': '',
                        'ФИО учителя': '',
                        'Место работы учителя': ''
                    };

                    for (let key in dataRow) {
                        data[key] = dataRow[key];
                    }

                    if (!!data["Класс обучения"]) {
                        data["Класс обучения"] = Number(data["Класс обучения"]);
                    } else {
                        data["Класс обучения"] = '';
                    }

                    if (!!data["Класс участия"]) {
                        data["Класс участия"] = Number(data["Класс участия"]);
                    } else {
                        data["Класс участия"] = '';
                    }

                    if (!!data["Балл"]) {
                        data["Балл"] = Number(data["Балл"].toString().replace(',', '.'));
                        data["Балл"] = Math.round(parseFloat(data["Балл"]) * 100) / 100;
                    } else {
                        data["Балл"] = '';
                    }

                    if (!!data["Максимальный балл"]) {
                        data["Максимальный балл"] = Number(data["Максимальный балл"].toString().replace(',', '.'));
                    } else {
                        data["Максимальный балл"] = '';
                    }

                    data['№'] = item.id;
                    data.id = item.id;
                    objectsArray.push(data);
                });
            },

            async loadSubjects() {
                this.mute = true;
                const year = new Date().getFullYear();
                await fetch(`../api/olympiad-data?stage=1&year=${year}`)
                .then(res => res.json())
                .then(json => this.subjects = json)
                .catch(error => {
                    this.errored = true;
                });
                this.mute = false;
            },

            async saveToExcel()  {
                let subjectName = `Предмет_${this.olympiad.code}_${this.olympiad.olympiad_date}`;
                let data = [];
                this.prepareDataToShow(data);
                data.forEach(obj => {

                    if (!!obj["Дата рождения"]) {
                        let birthDate = moment(obj["Дата рождения"], 'DD.MM.YYYY').format('YYYY-MM-DD');
                        obj["Дата рождения"] = this.JSDateToExcelDate(new Date(birthDate));
                    }
                    !!obj.id ? delete obj.id : false;
                    obj["Предмет"] = this.olympiad.name;
                    obj["Код ОО"] = this.user.school_code;
                    obj["Наименование ОО"] = this.schoolName;
                    obj["Район"] = this.areaName;
                    !!obj.school_code ? delete obj.school_code : false;
                });

                let fileName =  subjectName + ".xlsx";
                let res = alasql('SELECT * INTO XLSX("' + fileName + '",?) FROM ?', [[{ headers:true }], [data]]);
            },

            JSDateToExcelDate(inDate) {
                var returnDateTime = 25569.0 + ((inDate.getTime() - (inDate.getTimezoneOffset() * 60 * 1000)) / (1000 * 60 * 60 * 24));
                return parseInt(returnDateTime, 10);
            },

            dateFormat(date) {
                return moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');
            },

            timer(limit = 3) {
                let seconds = 0;
                let intervalId = setInterval(() => {
                    seconds++;
                    if (seconds > limit) {
                        clearInterval(intervalId);
                        document.getElementById("success_text").innerText = "";
                        document.getElementById("success_alert").style.display = "none";
                    }
                }, 1000);
            },

            timeCounter() {
                let timeLimit = 18;
                this.minutes = timeLimit;
                let intervalId = setInterval(() => {
                    this.minutes--;
                    if (this.minutes <= 0) {
                        clearInterval(intervalId);
                        this.minutes = timeLimit;
                    }
                }, 600000);
            },

            checkPage: function(page){
                return page === this.current_page;
            },

            nextPage() {
              let i = this.current_page;
              i++;
              if (i <= this.pages[this.pages.length - 1]) {
                  this.current_page = i;
                  this.paginate(this.fileData, this.pageSize, this.current_page);
              } else {
                  return;
              }
            },

            paginate(array, pageSize, pageNumber) {
                this.pageData = array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
                this.current_page = pageNumber;
            },

            previousPage() {
                let i = this.current_page;
                i--;
                if (i >= 1) {
                    this.current_page = i;
                    this.paginate(this.fileData, this.pageSize, this.current_page);
                } else {
                    return;
                }
            },

            resetPageNumbers() {
                this.pages = [];
                this.total = this.fileData.length;
                for (let i = 1; i <= Math.ceil(this.total/this.pageSize); i++) {
                    this.pages.push(i);
                }
            },

            showModal(i = null) {
                this.selectedPageData = {};
                this.rowIndex = i;
                if (!this.olympiad.isOlympiadFinished) {
                    if (i === null) {
                        this.clickCount++;
                        this.modalRole = 2;
                        this.selectedPageData = {
                            '№': 1, 'Снилс': '', 'Направление': '', 'Виды практик': '', 'Фамилия': '', 'Имя': '', 'Отчество': '', 'Дата рождения': '',
                            'Пол': '', 'Участник с ОВЗ (да/нет)': 'нет', 'Класс обучения': null, 'Класс участия': null,
                            'Балл': null, 'Максимальный балл': null, 'Статус': '',
                            'Призёр муниципального этапа прошлого года': '',
                            'ФИО учителя': '', 'Место работы учителя': '', subjectName: '',
                            isEdited: false, school_code: this.user.school_code
                        };
                        this.modalTitle = 'Ввод новых данных';
                    } else {
                        this.clickCount++;
                        Object.assign(this.selectedPageData, this.pageData[i]);
                        this.selectedPageData['Отчество'] = !!this.pageData[i]['Отчество'] ? this.pageData[i]['Отчество'] : '';
                        this.selectedPageData['Дата рождения'] = !!this.selectedPageData['Дата рождения'] ? moment(this.selectedPageData['Дата рождения'], 'DD.MM.YYYY').format('YYYY-MM-DD') : '';
                        Object.defineProperty(this.selectedPageData, "isEdited", {value: false, writable: true, configurable: true});
                        this.modalRole = 1;
                        this.modalTitle = 'Редактирование данных';
                    }
                    this.selectedPageData.subjectName = this.olympiad.name.toLowerCase();
                    this.isModalVisible = true;
                } else {
                    alert(`Предмет закрыт ${this.olympiad.appeal_end} ! \n\nВы можете выгрузить протокол.\n\nЗагрузка, редактирование не активно.`);
                }
            },

            closeModal() {
                this.isModalVisible = false;
                this.mute = false;
                this.modalRoleState = 0;
            },

            async getUser() {
                this.mute = true;
                await fetch('../user')
                .then(res => res.json())
                .then(json => this.user = json)
                .catch(error => {
                    this.errored = true;
                });
                this.mute = false;
            },
        },

        watch: {
            mute(val) {
                document.getElementById('mute').className = val ? "on" : "";
                document.getElementById('mute').innerHTML = val ? '<div class="loader"></div>' : '';
            },

            minutes(val) {
                !!this.olympiadId ? this.loadSubjects() : false;
            },

            olympiad(val) {
              this.showTechnology = val.name.toLowerCase() === "технология";
            }
        },

        mounted() {
            document.getElementById("success_alert").style.display = "none";
            this.token = $("input[name='_token']").val();
            this.getUser();
            this.loadSubjects();
            this.timeCounter();
        },
    }
</script>

<style scoped>
    table th, td {
        padding: 4px;
    }
</style>
