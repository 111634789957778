<template>
    <div class="container container-fluid" id="body-container">
        <div id="mute"></div>
        <hr>
        <h4>ВСОШ</h4>
        <div class="subject-container">
                <article
                    class="olympiad regional"
                    v-for="subject in regionalSubjectsState" :key="subject.code"
                    @click="saveToExcel(subject.olympiad_id)"
                >
                    <label>{{ subject.name }}</label>
                    <label>{{ dateFormat(subject.olympiad_date) }}</label>
                    <label class="total-records">Всего записей: {{ subject.total }}</label>
                </article>
        </div>

        <hr>
        <hr>
        <h4>РОШ</h4>
        <div class="subject-container">
                <article
                    class="olympiad local"
                    v-for="subject in localSubjectsState" :key="subject.code"
                    @click="saveToExcel(subject.olympiad_id)"
                >
                    <label>{{ subject.name }}</label>
                    <label>{{ dateFormat(subject.olympiad_date) }}</label>
                    <label class="total-records">Всего записей: {{ subject.total }}</label>
                </article>
        </div>
        <hr>

        <section v-if="errored" class="alert alert-danger alert-dismissible alert-important mt-3" role="alert" id="failed_alert">
            <span id="failed_text">К сожалению, в данный момент мы не можем получить эту информацию, пожалуйста, попробуйте вернутьсяпозже.</span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true" style="color: red; font-weight: 600;">&times;</span>
            </button>
        </section>

        <section class="alert alert-success alert-dismissible alert-important mt-3" role="alert" id="success_alert">
            <span id="success_text"></span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true" style="color: red; font-weight: 600;">&times;</span>
            </button>
        </section>

</div>
</template>
<script>
    export default {
        name: "SchoolProtocolExportWatch",
        data() {
            return {
                countBySubject: [],
                dbData: [],
                error: {},
                errored: false,
                fileData: [],
                message: '',
                minutes: 0,
                mute: false,
                olympiad: null,
                olympiadId : null,
                schools: [],
                subjects: [],
                user: null,
            }
        },
        methods: {
            async getDbData(olympiadId) {
                this.mute = true;
                let protocolData = null;
                await fetch(`../api/get-protocol?olympiad_id=${olympiadId}`)
                .then(res => res.json())
                .then(json => protocolData = json)
                .catch(error => {
                    console.log(error);
                    this.errored = true;
                });
                this.message = protocolData['message'];
                this.dbData = protocolData['dataRows'];
                this.schools = protocolData['schools'];
                this.olympiad = this.subjects.find(item => item.olympiad_id === olympiadId);
                this.prepareDataToShow(this.fileData);
                protocolData = null;
                this.mute = false;
            },

            // async getCountBySubject() {
            //     this.mute = true;
            //     let protocolData = null;
            //     await fetch(`../api/get_count_by_subject`)
            //     .then(res => res.json())
            //     .then(json => this.countBySubject = json)
            //     .catch(error => {
            //         console.log(error);
            //         this.errored = true;
            //     });
            //     this.subjects.forEach(subject => {
            //         // Object.defineProperty(subject, 'total', {value: 0, writable: true,  enumerable: true, configurable: true});
            //         Vue.set(subject, 'total');
            //         let foundObj = this.countBySubject.find(item => item.olympiad_id === subject.olympiad_id);
            //         subject.total = foundObj ? foundObj.total : 0;

            //     });
            //     this.mute = false;
            // },

            prepareDataToShow(objectsArray = []) {
                this.dbData.forEach(item => {
                    let dataRow = JSON.parse(item.data_row);
                    let data = {
                        '№': 0, 'Район': '',
                        'Предмет': '',
                        'Направление': '',
                        'Виды практик': '',
                        'Код ОО': '',
                        'Наименование ОО': '',
                        'Фамилия': '',
                        'Имя': '',
                        'Отчество': '',
                        'Дата рождения': null,
                        'Пол': '',
                        'Участник с ОВЗ (да/нет)': '',
                        'Класс обучения': null,
                        'Класс участия': null,
                        'Балл': null,
                        'Максимальный балл': null,
                        'Статус': '',
                        'Призёр муниципального этапа прошлого года': '',
                        'ФИО учителя': '',
                        'Место работы учителя': ''
                    };

                    for (let key in dataRow) {
                        data[key] = dataRow[key];
                    }

                    data["Предмет"] = this.olympiad.name;
                    let school = this.schools.find(element => element.code === item.school_code);

                    if (!!school && !!school.name) {
                        data["Код ОО"] = school.code
                        data["Наименование ОО"] =  school.name;
                        data["Район"] = school.areaName;
                    } else {
                        data["Район"] = this.schools[0].governmentName;
                        data["Код ОО"] = 0;
                    }

                    const gender = ['m', 'M', 'м', 'М', 'ж', 'Ж'];
                    if (!!data["Пол"]) {
                        data["Пол"] = data["Пол"].toString().trim().slice(0);
                        data["Пол"] = gender.includes(data["Пол"]) ? data["Пол"].toUpperCase() : data["Пол"];
                    } else {
                        data["Пол"] = '-';
                    }

                    if (!!data["Класс обучения"]) {
                        data["Класс обучения"] = !Number.isNaN(data["Класс обучения"]) ? Number(data["Класс обучения"]) : data["Класс обучения"];
                    } else {
                        data["Класс обучения"] = -1;
                    }

                    if (!!data["Класс участия"]) {
                        data["Класс участия"] = !Number.isNaN(data["Класс участия"]) ? Number(data["Класс участия"]) : data["Класс участия"];
                    } else {
                        data["Класс участия"] = -1;
                    }

                    if (!!data["Дата рождения"]) {
                        let birthDate = moment(data["Дата рождения"], 'DD.MM.YYYY').format('YYYY-MM-DD');
                        data["Дата рождения"] = this.JSDateToExcelDate(new Date(birthDate));
                    } else {
                        data["Дата рождения"] = -1;
                    }

                    if (!!data["Балл"]) {
                        data["Балл"] = Number(data["Балл"].replace(/,/, '.'));
                    } else {
                        data["Балл"] = 0;
                    }

                    if (!!data["Максимальный балл"]) {
                        data["Максимальный балл"] = Number(data["Максимальный балл"].replace(/,/, '.'));
                    } else {
                        data["Максимальный балл"] = '-';
                    }

                    data['Статус'] = !!data['Статус'] ? data['Статус'] : '-';
                    data['ФИО учителя'] = !!data['ФИО учителя'] ? data['ФИО учителя'] : '-';
                    data['Место работы учителя'] = !!data['Место работы учителя'] ? data['Место работы учителя'] : '-';

                    data['№'] = !!item.id ? item.id : 0;

                    objectsArray.push(data);
                });
            },

            async loadSubjects() {
                this.mute = true;
                const year = new Date().getFullYear();
                await fetch(`../api/olympiad-data?stage=1&year=${year}`)
                .then(res => res.json())
                .then(json => this.subjects = json)
                .catch(error => {
                    console.log(error);
                    this.errored = true;
                });
                this.subjects.forEach(subject => Vue.set(subject, 'isFinished', this.getOlympiadState(subject)));
                this.mute = false;
            },

            getOlympiadState(subject) {
                let date = new Date();
                date = date.getTime();
                let olympEndDate = new Date(subject.appeal_end).getTime();
              return date > olympEndDate;
            },

            async saveToExcel(olympiadId)  {
                await this.getDbData(olympiadId);

                if (this.message.length > 0) {
                    this.showMessage(10, this.message);
                    return;
                }

                if (this.fileData.length === 0) {
                    const div = document.createElement('div');
                    const divHeader = document.createElement('span');
                    const button = document.createElement('button');
                    const buttonSpan =  document.createElement('span');

                    div.className = "alert alert-danger alert-important mt-3";
                    div.id = "warning";
                    div.setAttribute("role", "alert");
                    div.style.position = "absolute";
                    div.style.width = "60%";
                    div.style.left = "calc(50% - 60% / 2)";
                    div.style.top = "252px";

                    divHeader.style.width = "120px";
                    divHeader.innerText = 'Протокол пуст! Нет участников по данному предмету';

                    button.setAttribute("type", "button");
                    button.className = "close";
                    button.setAttribute("data-dismiss","alert");
                    button.setAttribute("aria-label","alert");

                    buttonSpan.setAttribute("aria-hidden", true);
                    buttonSpan.style.color = "red";
                    buttonSpan.style.fontWeight = "600";
                    buttonSpan.innerHTML = '&times';

                    button.appendChild(buttonSpan);
                    div.appendChild(divHeader).appendChild(button);
                    document.getElementById('body-container').appendChild(div);

                    let seconds = 0;
                    let intervalId = setInterval(() => {
                        seconds++;
                        if (seconds > 20) {
                            clearInterval(intervalId);
                            !!document.getElementById("warning") ? document.getElementById("warning").remove() : false;
                        }
                    }, 1000);
                    return;
                }

                let fileName = `Предмет_ШЭ_${this.olympiad.code}_${this.olympiad.olympiad_date}.xlsx`;
                let tmpArr = [];
                const fields = [
                    '№', 'Район', 'Предмет', 'Направление', 'Виды практик', 'Код ОО', 'Наименование ОО', 'Фамилия', 'Имя',
                    'Отчество', 'Дата рождения', 'Пол', 'Участник с ОВЗ (да/нет)', 'Класс обучения',
                    'Класс участия', 'Балл', 'Максимальный балл', 'Статус', 'Призёр муниципального этапа прошлого года',
                    'ФИО учителя', 'Место работы учителя'
                ];
                this.olympiad.name !== "Технология" ? fields.splice(3, 2): 0;

                this.fileData.forEach(obj => {
                    const tmpObj = {}
                    fields.forEach(item => {
                        if (item === 'Дата рождения') {
                            tmpObj[item] = !!obj[item] && obj[item] > 36892 ? obj[item] : 'неверная дата';
                        } else {
                            tmpObj[item] = !!obj[item] ? obj[item] : '';
                        }
                    })
                    tmpArr.push(tmpObj);
                });

                this.dbData = [];
                let res = alasql('SELECT * INTO XLSX("' + fileName + '",?) FROM ?', [[{ headers:true}], [tmpArr]]);
                this.fileData = [];
            },

            JSDateToExcelDate(inDate) {
                let returnDateTime = 25569.0 + ((inDate.getTime() - (inDate.getTimezoneOffset() * 60 * 1000)) / (1000 * 60 * 60 * 24));
                return parseInt(returnDateTime, 10);
            },

            dateFormat(date) {
                return moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');
            },

            showMessage(limit = 10, message) {
                let seconds = 0;
                const alertDiv = document.getElementById("success_alert");
                const alertMessage = document.getElementById("success_text");
                alertDiv.classList.remove('alert-success');
                alertDiv.classList.add('alert-info');
                alertMessage.innerText = message;
                alertDiv.style.display = "block";

                let intervalId = setInterval(() => {
                    seconds++;
                    if (seconds > limit) {
                        clearInterval(intervalId);
                        alertMessage.innerText = "";
                        alertDiv.style.display = "none";
                        alertDiv.classList.remove('alert-info');
                        alertDiv.classList.add('alert-success');
                    }
                }, 1000);
            },

            timeCounter() {
                let timeLimit = 32;
                this.minutes = timeLimit;
                let intervalId = setInterval(() => {
                    this.minutes--;
                    if (this.minutes <= 0) {
                        clearInterval(intervalId);
                        this.minutes = timeLimit;
                    }
                }, 300000);
            },

            async getUser() {
                this.mute = true;
                await fetch('../user')
                .then(res => res.json())
                .then(json => this.user = json)
                .catch(error => {
                    console.log(error);
                    this.errored = true;
                });
                this.mute = false;
            },
        },

        watch: {
            mute(val) {
                document.getElementById('mute').className = val ? "on" : "";
                document.getElementById('mute').innerHTML = val ? '<div class="loader"></div>' : '';
            },

            minutes(val) {
                this.loadSubjects();
            }
        },

        mounted() {
            document.getElementById("success_alert").style.display = "none";
            this.getUser();
            this.loadSubjects();
            this.timeCounter();
            
        },

        computed: {
            regionalSubjectsState: function () {
                // this.getCountBySubject();
                return this.subjects.filter(subject => subject.level === 1)
            },
            localSubjectsState: function () {
                // this.getCountBySubject();
                return this.subjects.filter(subject => subject.level === 2)
            },
        }
    }
</script>

<style scoped>
</style>
