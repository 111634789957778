<template>
    <div class="container container-fluid">
        <div id="mute"></div>
        <hr>
        <h4>Олимпиады регионального уровня</h4>
        <div class="subject-container">
                <article
                    class="olympiad regional"
                    v-for="subject in regionalSubjectsState" :key="subject.code"
                    @click="saveToExcel(subject.olympiad_id)"
                >
                    <label>{{ subject.name }}</label>
                    <label>{{ dateFormat(subject.olympiad_date) }}</label>
                    <label class="total-records">Всего записей: {{ subject.total }}</label>
                </article>
        </div>

        <hr>
        <hr>
        <h4>Олимпиады республикаского уровня</h4>
        <div class="subject-container">
                <article
                    class="olympiad local"
                    v-for="subject in localSubjectsState" :key="subject.code"
                    @click="saveToExcel(subject.olympiad_id)"
                >
                    <label>{{ subject.name }}</label>
                    <label>{{ dateFormat(subject.olympiad_date) }}</label>
                    <label class="total-records">Всего записей: {{ subject.total }}</label>
                </article>
        </div>
        <hr>

        <section v-if="errored" class="alert alert-danger alert-dismissible alert-important mt-3" role="alert" id="failed_alert">
            <span id="failed_text">К сожалению, в данный момент мы не можем получить эту информацию, пожалуйста, попробуйте вернуться позже.</span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true" style="color: red; font-weight: 600;">&times;</span>
            </button>
        </section>

        <section class="alert alert-success alert-dismissible alert-important mt-3" role="alert" id="success_alert">
            <span id="success_text"></span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true" style="color: red; font-weight: 600;">&times;</span>
            </button>
        </section>

</div>
</template>
<script>
    export default {
        name: "SchoolProtocolExport",
        data() {
            return {
                dbData: [],
                error: {},
                errored: false,
                fileData: [],
                message: '',
                minutes: 0,
                mute: false,
                olympiad: null,
                olympiadId : null,
                schools: [],
                subjects: [],
                user: null,
            }
        },
        methods: {
            async getDbData(olympiadId) {
                this.mute = true;
                let protocolData = null;
                await fetch(`/api/get-protocol?olympiad_id=${olympiadId}`)
                .then(res => res.json())
                .then(json => protocolData = json)
                .catch(error => {
                    console.log(error);
                    this.errored = true;
                });
                this.mute = false;
                this.message = protocolData['message'];
                this.dbData = protocolData['dataRows'];
                this.schools = protocolData['schools'];
                this.olympiad = this.subjects.find(item => item.olympiad_id === olympiadId);
                this.prepareDataToShow(this.fileData);
            },

            prepareDataToShow(objectsArray = []) {
                this.dbData.forEach(item => {
                    let dataRow = JSON.parse(item.data_row);
                    let data = {
                        '№': 0,
                        'Район': '',
                        'Предмет': '',
                        'Код ОО': '',
                        'Наименование ОО': '',
                        'Фамилия': '',
                        'Имя': '',
                        'Отчество': '',
                        'Дата рождения': null,
                        'Пол': '',
                        'Участник с ОВЗ (да/нет)': '',
                        'Класс обучения': null,
                        'Класс участия': null,
                        'Балл': null,
                        'Добавлено баллов по итогам апелляций': null,
                        'Итоговый балл': null,
                        'Максимальный балл': null,
                        'Статус': '',
                        'Призёр республиканского этапа прошлого года': '',
                        'ФИО учителя': '',
                        'Место работы учителя': ''
                    };
                    for (let key in dataRow) {
                        data[key] = dataRow[key];
                    }
                    data["Район"] = item.governmentName;
                    let school = this.schools.find(element => element.code == item.school_code);
                    if (!!school && !!school.name) {
                        data["Код ОО"] = school.code;
                        data["Наименование ОО"] =  school.name;
                        data["Район"] = school.areaName;
                    };
                    data["Предмет"] = this.olympiad.name;
// console.log(data);
                    if (!!data["Класс обучения"]) {
                        data["Класс обучения"] = Number(data["Класс обучения"]);
                    } else {
                        data["Класс обучения"] = '';
                    };

                    if (!!data["Класс участия"]) {
                        data["Класс участия"] = Number(data["Класс участия"]);
                    } else {
                        data["Класс участия"] = '';
                    };

                    if (!!data["Дата рождения"]) {
                        let birthDate = moment(data["Дата рождения"], 'DD.MM.YYYY').format('YYYY-MM-DD');
                        data["Дата рождения"] = this.JSDateToExcelDate(new Date(birthDate));
                    };

                    if (!!data["Балл"]) {
                        data["Балл"] = Number(data["Балл"].replace(/,/, '.'));
                    } else {
                        data["Балл"] = '';
                    };

                    if (!!data["Добавлено баллов по итогам апелляций"]) {
                        data["Добавлено баллов по итогам апелляций"] = Number(data["Добавлено баллов по итогам апелляций"].replace(/,/, '.'));
                    } else {
                        data["Добавлено баллов по итогам апелляций"] = '';
                    };

                    if (!!data["Итоговый балл"]) {
                        data["Итоговый балл"] = Number(data["Итоговый балл"].replace(/,/, '.'));
                    } else {
                        data["Итоговый балл"] = '';
                    };

                    if (!!data["Максимальный балл"]) {
                        data["Максимальный балл"] = Number(data["Максимальный балл"].replace(/,/, '.'));
                    } else {
                        data["Максимальный балл"] = '';
                    }

                    data['№'] = !!item.id ? item.id : 0;

                    objectsArray.push(data);
                    data = {};
                });
            },

            async loadSubjects() {
                const year = new Date().getFullYear();

                await fetch(`../api/olympiad-data?stage=2&year=${year}`)
                .then(res => res.json())
                .then(json => this.subjects = json)
                .catch(error => {
                    console.log(error);
                    this.errored = true;
                });
                this.subjects.forEach(subject => Vue.set(subject, 'isFinished', this.getOlympiadState(subject)))
                this.mute = false
            },

            getOlympiadState(subject) {
                let date = new Date();
                date = date.getTime();
                let olympEndDate = new Date(subject.appeal_end).getTime();
                let isFinished = date > olympEndDate ? true : false;
                return isFinished;
            },

            async saveToExcel(olympiadId)  {
                await this.getDbData(olympiadId);

                if (this.message.length > 0) {
                    this.showMessage(10, this.message);
                    return;
                }

                if (this.fileData.length === 0) {
                    this.showMessage(10, 'Протокол по предмету в базе данных не найден');
                    return;
                };

                let fileName = `Предмет_МЭ_${this.olympiad.code}_${this.olympiad.olympiad_date}.xlsx`;
                let options = {
                    headers:true,
                    column: {style:{Font:{Bold:"1"}}},
                    columns: [
                        { columnid: '№', width: 20 },
                        { columnid: 'Район', width: 100 },
                        { columnid: 'Предмет', width: 100 },
                        { columnid: 'Код ОО', width: 50 },
                        { columnid: 'Наименование ОО', width: 150 },
                        { columnid: 'Фамилия', width: 90 },
                        { columnid: 'Имя', width: 90 },
                        { columnid: 'Отчество', width: 90, cell: {format: function (value) {if (value === "-") {return ""; } else return value;}} },
                        { columnid: 'Дата рождения', width: 60 },
                        { columnid: 'Пол', width: 25 },
                        { columnid: 'Участник с ОВЗ (да/нет)', width: 60 },
                        { columnid: 'Класс обучения', width: 40 },
                        { columnid: 'Класс участия', width: 40 },
                        { columnid: 'Балл', width: 40 },
                        { columnid: 'Добавлено баллов по итогам апелляций', width: 40 },
                        { columnid: 'Итоговый балл', width: 40 },
                        { columnid: 'Максимальный балл', width: 40 },
                        { columnid: 'Призёр республиканского этапа прошлого года', width: 70 },
                        { columnid: 'ФИО учителя', width: 100 },
                        { columnid: 'Место работы учителя', width: 200 },
                    ],
                };

                this.dbData = [];
                let res = alasql('SELECT * INTO XLSX("' + fileName + '",?) FROM ?', [options, this.fileData])
                // let res = alasql('SELECT * INTO XLSX("' + fileName + '",?) FROM ?', [[{ headers:true }], [this.fileData]]);
                this.fileData = [];
            },

            JSDateToExcelDate(inDate) {
                var returnDateTime = 25569.0 + ((inDate.getTime() - (inDate.getTimezoneOffset() * 60 * 1000)) / (1000 * 60 * 60 * 24));
                return parseInt(returnDateTime, 10);
            },

            dateFormat(date) {
                return moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');
            },

            showMessage(limit = 10, message) {
                let seconds = 0;
                const alertDiv = document.getElementById("success_alert");
                const alertMessage = document.getElementById("success_text");
                alertDiv.classList.remove('alert-success');
                alertDiv.classList.add('alert-info');
                alertMessage.innerText = message;
                alertDiv.style.display = "block";

                let intervalId = setInterval(() => {
                    seconds++;
                    if (seconds > limit) {
                        clearInterval(intervalId);
                        alertMessage.innerText = "";
                        alertDiv.style.display = "none";
                        alertDiv.classList.remove('alert-info');
                        alertDiv.classList.add('alert-success');
                    }
                }, 1000);
            },

            timeCounter() {
                let timeLimit = 32;
                this.minutes = timeLimit;
                let intervalId = setInterval(() => {
                    this.minutes--;
                    if (this.minutes <= 0) {
                        clearInterval(intervalId);
                        this.minutes = timeLimit;
                    }
                }, 300000);
            },

            async getUser() {
                this.mute = true;
                await fetch('../user')
                .then(res => res.json())
                .then(json => this.user = json)
                .catch(error => {
                    console.log(error);
                    this.errored = true;
                });
                this.mute = false;
            },
        },

        watch: {
            mute(val) {
                document.getElementById('mute').className = val ? "on" : "";
                document.getElementById('mute').innerHTML = val ? '<div class="loader"></div>' : '';
            },

            minutes(val) {
                this.loadSubjects();
            }
        },

        mounted() {
            document.getElementById("success_alert").style.display = "none";
            this.getUser();
            this.loadSubjects();
            this.timeCounter();
        },

        computed: {
            regionalSubjectsState: function () {
                return this.subjects.filter(subject => subject.level == 1 && subject.isFinished)
            },
            localSubjectsState: function () {
                return this.subjects.filter(subject => subject.level == 2 && subject.isFinished)
            },
        }
    }
</script>

<style scoped>
</style>