<template>
    <div class="container">
        <div id="mute"></div>
        <div class="d-flex justify-content-between mt-0 mb-0 p-0">
            <div>
                <input
                    class="search bg-white rounded px-2"
                    id="search" name="query"
                    type="search"
                    placeholder="Поиск"
                    autocomplete="off"
                    v-model="query"
                    @keyup.stop="search(query)"
                />
                <button
                    class="clear-search"
                    type="button"
                    aria-label="clear-search"
                    v-show="query !== ''"
                    @click="search(query='')"
                    title="Очистить поиск"
                >
                    <span
                        aria-hidden="true"
                        style="font-weight: 600;"
                    >
                        &times;
                    </span>
                </button>
            </div>
            <div>
                <label>Показать по
                    <select
                        class="rounded bg-white" id="showcount" name="per_page"
                        v-model="per_page"
                        @change="getData()"
                    >
                        <option
                            v-for="option in options"
                            :key="'opt' + option"
                            v-bind:value="option"
                        >
                            {{option}}
                        </option>
                    </select>
                </label>
            </div>
            <div class="mr-1" v-if="total">
                <span class="align-middle m-0">Всего: {{total}}</span>
            </div>
            <div class="mr-4" v-else>
                <span class="align-middle m-0">Всего:</span>
            </div>
        </div>

        <table class="table table-bordered table-striped table-hover table-sm mb-3">
            <thead>
            <tr class="align-middle text-center">
                <th>№</th>
                <th>Район</th>
                <th>Школа</th>
                <th>ФИО ответственного</th>
                <th>E-mail адрес</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr class="align-middle" v-for="(item, index) in gridData" :key="'row' + index"
            >
                <td class="align-middle text-center">
                    <span>{{ index + 1 + (current_page - 1) * per_page }}</span>
                </td>
                <td class="align-middle">
                    <span>{{item.areaName}}</span>
                </td>
                <td class="align-middle">
                    <a :href="'/school/' + item.school_id">{{item.school_name}}</a>
                </td>
                <td class="align-middle">
                    <span v-if="item.name && !isEditing || item.id !== editngItemId">{{ item.name }}</span>
                    <input
                        v-else-if="isEditing"
                        class="from-control user-data"
                        type="text" name="name"
                        v-model="userName"
                        v-bind:id="'n' + index"
                    >
                </td>
                <td class="align-middle">
                    <span v-if="item.email && !isEditing || item.id !== editngItemId">{{item.email}}</span>
                    <input
                        v-else-if="isEditing"
                        class="from-control user-data"
                        type="email" name="email"
                        v-model="userEmail"
                        v-bind:id="'m' + index"
                    >
                </td>
                <td class="align-middle text-center">
                    <div class="btn-group btn-group-sm m-0 p-0">
                        <button
                            class="btn btn-success"
                            :disabled="isEditing && item.id == editngItemId ? 'disabled' : false"
                            @click="itemDataEdit(item)"
                        >
                            Изменить
                        </button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>

        <div class="row col-md-12 mb-2 align-items-center" id="gridFooter" v-if="total">
            <div class="col-md m-0">
                Страница {{ current_page }} из {{ last_page }}
            </div>
            <div class="col-md m-0 d-flex justify-content-center">
                <nav aria-label="Page navigation">
                    <ul class="pagination pagination-sm m-0">
                        <li class="page-item"><a class="page-link" @click.prevent="getData(first_page_url)"> &#x3c;&#x3c; </a></li>
                        <li class="page-item" v-if="checkUrlNotNull(prev_page_url)">
                            <a class="page-link" @click.prevent="getData(prev_page_url)" > &#x3c; </a>
                        </li>
                        <li class="page-item"
                            v-for="page in pages"
                            :key="'page' + page"
                            v-show="page > current_page - 3 && page < current_page + 3"
                            v-bind:class="{'active': checkPage(page)}"
                        >
                            <a class="page-link" @click.prevent="getData(page)">{{ page }}</a>
                        </li>
                        <li class="page-item" v-if="checkUrlNotNull(next_page_url)">
                            <a class="page-link" @click.prevent="getData(next_page_url)"> &#x3e; </a>
                        </li>
                        <li class="page-item"><a class="page-link" @click.prevent="getData(last_page_url)"> &#x3e;&#x3e; </a></li>
                    </ul>
                </nav>
            </div>
            <div class="col-md m-0 d-flex justify-content-end align-items-center" id="for_page_button">
                <div class="p-0 m-0">
                    <input class="w-25 number-input text-center border border-primary rounded float-right mr-2"
                           v-model="go_to_page"
                           @keyup="checkPageNumber(go_to_page)"
                           @keyup.enter.self.stop.prevent="getData(go_to_page)"
                           placeholder="стр."
                    />
                </div>
                <div>
                    <button
                        class="btn btn-sm btn-primary" id="go_to_page"
                        :disabled="!go_to_page"
                        @click.prevent="getData(go_to_page)"
                    >
                        Перейти
                    </button>
                </div>
            </div>
        </div>
        <hr/>
        <hr/>
        <div style="font-size: 18px">Загрузка пользователей из файла (<button type="button" class="p-0 btn btn-link" @click="getTemplateFile()">Скачать форму для ввода новых пользователей</button>)</div>
        <div class="alert alert-warning my-3">
            <span style="font-weight:600;font-size:16px">Внимание! При загрузке из файла из базы данных будет удалена вся информация связанная со старыми пользователями.</span>
        </div>
        <div class="d-flex">
            <div class="d-flex justify-content-center">
                <div class="row align-items-center px-3" id="list-upload">
                    <label for="file-input"></label>
                    <input
                        type="file" id="file-input" ref="file" accept=".xls, .xlsx, .ods"
                    />

                    <div class="m-0 p-0 ml-2">
                        <button
                            class="btn btn-outline-secondary"
                            :hidden="!hideSave"
                            :disabled="false"
                            @click="readData"
                        >
                            Считать
                        </button>
                        <button
                            class="btn btn-outline-success" id="submit"
                            :hidden="hideSave"
                            @click="saveSchoolUsers"
                        >
                            Сохранить
                        </button>
                        <button
                            class="btn btn-outline-danger" id="cancel"
                            @click="cancelImport"
                        >
                            Отмена
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="fileData.length > 0">
            <table class="table table-bordered table-striped table-hover table-responsive-lg mt-3 mb-3">
                <thead>
                <tr>
                    <th class="align-middle text-center">№</th>
                    <th class="align-middle text-center">Код района</th>
                    <th class="align-middle text-center">Район</th>
                    <th class="align-middle text-center">Код ОО</th>
                    <th class="align-middle text-center">Наименование ОО</th>
                    <th class="align-middle text-center">ФИО ответственного</th>
                    <th class="align-middle text-center">Адрес e-mail</th>
                    <th class="align-middle text-center">Пароль</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in fileData" :key="'tr' + index">
                    <td class="align-middle text-center row-num">{{ item["№"] }}</td>
                    <td class="align-middle text-center">{{item['Код района']}}</td>
                    <td class="align-middle text-center">{{item['Район']}}</td>
                    <td class="align-middle">{{item['Код ОО']}}</td>
                    <td class="align-middle">{{item['Наименование ОО']}}</td>
                    <td class="align-middle">{{ item['ФИО ответственного'] }}</td>
                    <td class="align-middle">{{item['Адрес e-mail']}}</td>
                    <td class="align-middle text-center">{{item['Пароль']}}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <hr>

        <section v-if="errored" class="alert alert-danger alert-dismissible alert-important mt-3" role="alert"
                 id="failed_alert">
            <span id="failed_text">К сожалению, в данный момент мы не можем получить эту информацию, пожалуйста, попробуйте вернуться
                позже.</span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true" style="color: red; font-weight: 600;">&times;</span>
            </button>
        </section>

        <div class="alert alert-success alert-dismissible alert-important mt-3" role="alert" id="success_alert">
            <span id="success_text"></span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true" style="color: red; font-weight: 600;">&times;</span>
            </button>
        </div>
        <button
                id="modalButton"
                type="button"
                class="btn"
                @click="showModal"
                hidden
        >
            Open Modal
        </button>
        <modal
                v-show="isModalVisible"
                :data = "selectedItemData"
                :title="modalTitle"
                @close="closeModal"
                @save="savePersonData(selectedItemData)"
        />
    </div>
</template>

<script>
import modal from './SchoolResponsibleModal';

export default {
        components: { modal },
        name: "SchoolResponsible",
        data: function () {
            return {
                allSchools: [],
                current_page: null,
                current_page_url: null,
                isEditing: false,
                editngItemId: null,
                errored: false,
                fileData: [],
                first_page_url: null,
                go_to_page: '',
                gridData: [],
                hideSave: true,
                mute: false,
                last_page: null,
                last_page_url: null,
                next_page_url: null,
                options: [5, 10, 15, 20],
                pages: [],
                per_page: 15,
                prev_page_url: null,
                query: '',
                sortKey: 'id',
                sortOrder: 1,
                stored: false,
                token: null,
                total: null,
                userEmail: '',
                userName: '',
                isModalVisible: false,
                modalTitle: 'Данные ответственного за олимпиаду',
                selectedItemData : [],
            }
        },

        methods: {
            loadData() {
                this.pages = [];
                this.fetchData();
            },

            async fetchData () {
                this.mute = true;
                await fetch(`/api/school-responsible?paginate=${this.per_page}`)
                .then(res => res.json())
                .then(json => {
                    this.gridData = json.rows.data;
                    this.total = json.rows.total;
                    this.last_page = json.rows.last_page;
                    this.next_page_url = json.rows.next_page_url;
                    this.prev_page_url= json.rows.prev_page_url;
                    this.first_page_url = '/api/school-responsible?page=1';
                    this.last_page_url = '/api/school-responsible?page=' + this.last_page;
                    this.current_page = json.rows.current_page;
                })
                .catch(error => {
                    this.errored = true;
                });
                this.resetPageNumbers();
                this.mute = false;
            },

            getData: function (request){
                let getPage;
                switch (request) {
                    case this.prev_page_url:
                        getPage = this.prev_page_url
                            + '&column=' + this.sortKey
                            + '&direction=' + this.sortOrder
                            + '&paginate=' + this.per_page;
                        break;
                    case this.next_page_url:
                        getPage = this.next_page_url
                            + '&column=' + this.sortKey
                            + '&direction=' + this.sortOrder
                            + '&paginate=' + this.per_page;
                        break;
                    case this.first_page_url:
                        getPage = this.first_page_url
                            + '&column=' + this.sortKey
                            + '&direction=' + this.sortOrder
                            + '&paginate=' + this.per_page;
                        break;
                    case this.last_page_url:
                        getPage = this.last_page_url
                            + '&column=' + this.sortKey
                            + '&direction=' + this.sortOrder
                            + '&paginate=' + this.per_page;
                        break;
                    case this.query:
                        getPage = '/api/school-responsible?'
                            + this.searchingKeyword()
                            + '&column=' + this.sortKey
                            + '&direction=' + this.sortOrder
                            + '&paginate=' + this.per_page;
                        break;
                    case this.go_to_page:
                        if ( this.go_to_page !== '') {
                            getPage = '/api/school-responsible?'
                                + 'page=' + this.go_to_page
                                + '&column=' + this.sortKey
                                + '&direction=' + this.sortOrder
                                + this.searchingKeyword()
                                + '&paginate=' + this.per_page;
                            this.clearPageNumberInputBox();
                        }
                        break;
                    default :
                        getPage = '/api/school-responsible?' +
                            'page=' + request +
                            '&column=' + this.sortKey +
                            '&direction=' + this.sortOrder + this.searchingKeyword() +
                            '&paginate=' + this.per_page;
                        break;
                }

                if (this.query === '' && getPage != null){
                    fetch(getPage)
                    .then(res => res.json())
                    .then(json => {
                        this.gridData = json.rows.data;
                        this.total = json.rows.total;
                        this.last_page =  json.rows.last_page;
                        this.next_page_url = json.rows.next_page_url;
                        this.prev_page_url = json.rows.prev_page_url;
                        this.last_page_url = '/api/school-responsible?page='
                            + this.last_page
                            + '&paginate=' + this.per_page;
                        this.current_page = json.rows.current_page;
                    })
                    .catch(error => {
                        this.errored = true;
                    });
                    this.mute = false
                } else {
                    if (getPage != null){
                        fetch(getPage)
                        .then(res => res.json())
                        .then(json => {
                            this.gridData = json.rows.data;
                            this.total = json.rows.total;
                            this.last_page =  json.rows.last_page;
                            this.next_page_url = (json.rows.next_page_url == null) ? null :
                                json.rows.next_page_url
                                + '&keyword=' + this.query
                                + '&paginate=' + this.per_page;
                            this.prev_page_url = (json.rows.prev_page_url == null) ? null :
                                json.rows.prev_page_url
                                + '&keyword=' +this.query
                                + '&paginate=' + this.per_page;
                            this.first_page_url = '/api/school-responsible?page=1&keyword='
                                + this.query
                                + '&paginate=' + this.per_page;
                            this.last_page_url = '/api/school-responsible?page='
                                + this.last_page + '&keyword='
                                + this.query + '&paginate='
                                + this.per_page;
                            this.current_page = json.rows.current_page;
                        })
                        .catch(error => {
                            this.errored = true;
                        });
                        this.mute = false;
                    }
                }
                this.resetPageNumbers();
            },

            searchingKeyword() {
              return this.query !== '' ? '&keyword=' + this.query : '';
            },

            search(query) {
                query !== '' ? this.getData(query) : this.fetchData();
                this.resetPageNumbers();
            },

            sortBy(key) {
                this.sortKey = key;
                this.sortOrder = (this.sortOrder === 1) ? -1 : 1;
                this.getData(1);
            },

            checkPage: function(page){
                return page === this.current_page;
            },

            checkPageNumber(){
                let newLine = "\r\n";
                if (!this.pageInRange()) {
                    alert('Пожалуйста, введите действительный номер страницы! '+ newLine +' (Номера страниц от 1 до ' + this.last_page + ')');
                    this.clearPageNumberInputBox();
                }
            },

            resetPageNumbers: function(){
                this.pages = [];
                for (let i = 1; i <= Math.ceil(this.total/this.per_page); i++) {
                    this.pages.push(i);
                }
            },

            checkUrlNotNull: function(url){
                return url != null;
            },

            clearPageNumberInputBox: function(){
                return this.go_to_page = '';
            },

            pageInRange: function(){
                return this.go_to_page <= parseInt(this.last_page);
            },

            savePersonData(selectedItem) {
                let item = selectedItem[0];
                if (item.id === null) {
                    let data = {
                        name : item.name,
                        email : item.email,
                        password : item.password,
                        area_code : item.areaCode,
                        gov_code : item.governmentCode,
                        school_id : item.school_id,
                        school_code: item.school_code
                    };

                    this.addUser(data);
                } else {
                    let data = {
                        id: item.id,
                        name : item.name,
                        email : item.email,
                        password : item.password
                    };

                    this.updateUser(data);
                }
                this.closeModal();
            },

            async addUser(data) {
                this.mute = true;
                await fetch('/add_user', {
                    headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json, text-plain, */*",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-CSRF-TOKEN": this.token
                    },
                    method: 'post',
                    credentials: "same-origin",
                    body: JSON.stringify({ person: data })
                })
                .then(response => response.json())
                .then(result => {
                    this.stored = true;
                    this.getData();
                    document.getElementById("success_alert").style.display = "block";
                    document.getElementById("success_text").innerText = result.message;
                    this.timer(4);
                })
                .catch(function(error) {
                    this.errored = true;
                });

                this.srored = false
                this.mute = false;
            },

            async updateUser(data) {
                this.mute = true;

                await fetch('/users', {
                    headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json, text-plain, */*",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-CSRF-TOKEN": this.token
                    },
                    method: 'post',
                    credentials: "same-origin",
                    body: JSON.stringify({ id: data.id, data: data })
                })
                .then(response => response.json())
                .then(result => {
                    this.stored = true;
                    this.getData();
                    document.getElementById("success_alert").style.display = "block";
                    document.getElementById("success_text").innerText = result.message;
                    console.log(result.message);
                    this.timer(4);
                })
                .catch(function(error) {
                    this.errored = true;
                });
                this.srored = false
                this.isEditing = false;
                this.editngItemId = null;
                this.mute = false;
            },

            timer(limit = 3) {
                let seconds = 0;
                let intervalId = setInterval(() => {
                    seconds++;
                    if (seconds > limit) {
                        clearInterval(intervalId);
                        document.getElementById("success_text").innerText = "";
                        document.getElementById("success_alert").style.display = "none";
                    }
                }, 1000);
            },

            async getAllSchools() {
                this.mute = true;
                await fetch('/api/school-responsible?paginate=10000&column=school_code&direction=2')
                .then(res => res.json())
                .then(json => this.allSchools = json.rows.data)
                .catch(error => {
                    this.errored = true;
                });
                this.mute = false;
            },

            async getTemplateFile() {
                await this.getAllSchools();
                let data = [];
                let i = 1;
                this.allSchools.forEach(obj => {
                    let tempObj = {'№': i, 'Код района': 0, 'Район': '', 'Код ОО': 0, 'Наименование ОО': '', 'ФИО ответственного': '', 'Адрес e-mail': '', 'Пароль': ''};
                    tempObj['Код района'] = obj.areaCode;
                    tempObj['Район'] = obj.areaName;
                    tempObj['Код ОО'] = obj.school_code;
                    tempObj['Наименование ОО'] = obj.school_name;
                    tempObj['ФИО ответственного'] = !!obj.name ? obj.name : '';
                    tempObj['Адрес e-mail'] = !!obj.email ? obj.email : '';
                    tempObj['Пароль'] = '';
                    i++;
                    data.push(tempObj);
                });
                let options = [{ headers:true }];
                let fileName =  "Форма_для_ввода_ответственных_в_школах.xlsx";
                let res = alasql('SELECT * INTO XLSX("' + fileName + '",?) FROM ?', [options, [data]]);
            },


            showModal() {
                this.isModalVisible = true;
            },

            closeModal() {
                this.isModalVisible = false;
                this.selectedItemData = [];
                this.mute = false;
            },

            itemDataEdit (item) {
                this.selectedItemData = [];
                this.selectedItemData.push(item);
                this.showModal();
            },

            readFileAsync(file) {
                return new Promise((resolve, reject) => {
                    let reader = new FileReader();
                    reader.onload = () => {
                        resolve(reader.result);
                    };
                    reader.onerror = reject;
                    reader.readAsArrayBuffer(file);
                })
            },

            arrayBufferToString(arrayBuffer) {
                let data = new Uint8Array(arrayBuffer);
                let workbook = XLSX.read(data, {type: 'array'});
                let sheetName = workbook.SheetNames[0];
                this.fileData = [];

                let worksheet = workbook.Sheets[sheetName];
                worksheet = XLSX.utils.sheet_to_json(worksheet);
                worksheet.forEach(obj => {

                    if (!!obj["Код района"] && !!obj["Код ОО"] && !!obj["ФИО ответственного"] && !!obj["Адрес e-mail"] && !!obj["Пароль"]) {
                        let tmpObj = {};
                        for (const [key, value] of Object.entries(obj)) {
                            if (typeof value === 'string') {
                                Object.defineProperty(obj, key, {
                                    value: value.trim().replace('\n', ' ').replace('  ', ' '),
                                });
                            }
                            tmpObj[key] = obj[key];
                        }
                        this.fileData.push(tmpObj);
                    }
                });
                worksheet = [];
            },

            async processFile(file) {
                this.mute = true;
                try {
                    let arrayBuffer = await this.readFileAsync(file);
                    this.arrayBufferToString(arrayBuffer);
                } catch (error) {
                    this.errored = true;
                }
                this.mute = false;
            },

            async readData() {
                let file = document.getElementById('file-input');
                if (file.files.length > 0) {
                    this.mute = true;
                    await this.processFile(file.files[0]);
                    this.mute = false;
                    if (this.fileData.length === 0) {
                        this.cancelImport();
                        alert('Опс...\nЧто-то не так!\nПроверьте содержимое загружаемого файла!\nВ нём имеется полностью пустой столбец!\nВ файле должна быть хотя бы одна строка, в которой заполнены все поля.');
                    } else {
                        this.hideSave = false;
                        alert('Внимание!\nЕсли на каких-то строках в файле одно из следующих полей\n"Код АТЕ", "ФИО ответственного",\n "Адрес e-mail", "Пароль" не заполнено, они будут пропущены.');
                    }
                } else {
                    alert('Файл не выбран');
                }
            },

            cancelImport() {
                this.fileData = [];
                this.hideSave = true;
                document.getElementById('file-input').value = '';
            },

            async saveSchoolUsers() {
                let total = this.fileData.length;
                const timer = ms => new Promise(res => setTimeout(res, ms));
                await this.getAllSchools();
                let ids = [];
                let dataLength = this.fileData.length;
                if (dataLength <= 100) {

                    for (let i = 0; i < dataLength; i++) {
                        let obj = this.allSchools.find(obj => (obj.school_code === this.fileData[i]["Код ОО"]) && !!obj.id );
                        !!obj ? ids.push(obj.id) : false;
                    }

                    this.mute = true;
                    await this.saveToDB( this.fileData, ids);
                    await timer(1000);
                    ids = [];
                } else {
                    let rows = [];
                    let counter = 0;
                    const loader = document.getElementById('mute');
                    if (!!loader) {
                        loader.innerHTML = `Осталось ${total} из ${total}` ;
                        loader.style.fontSize = '40px';
                        loader.style.fontWeight = '600';
                    }
                    this.mute = true;
                    for (let i = 0; i < dataLength; i++) {
                        rows.push(this.fileData[i]);
                        let obj = this.allSchools.find(obj => (obj.school_code === this.fileData[i]["Код ОО"]) && !!obj.id );
                        !!obj ? ids.push(obj.id) : false;

                        if ((i % 100) === 99) {
                            await this.saveToDB(rows, ids);
                            counter = dataLength - i;
                            !!loader ? loader.innerHTML = `Осталось ${counter - 1} из ${total}` : false;
                            rows = [];
                            await timer(1000);
                            ids = [];
                        }
                    }
                    if (counter > 0) {
                        await this.saveToDB(rows, ids);
                        !!loader ? loader.innerHTML = 0 : false;
                        rows = [];
                        ids = [];
                    }
                }

                this.stored = true;
                this.getData();
                document.getElementById("success_alert").style.display = "block";
                document.getElementById("success_text").innerText = "Пользователи сохранены";
                this.timer(3);

                this.mute = false;
                this.cancelImport();
                this.fileData = [];
                this.hideSave = true;
            },

            async saveToDB(data, ids) {
                await fetch('/add-school-users', {
                    headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json, text-plain, */*",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-CSRF-TOKEN": this.token
                    },
                    method: 'post',
                    credentials: "same-origin",
                    body: JSON.stringify({ users: data, ids: ids })
                })
                .then(function(response){
                    response.json();
                })
                .then(function(json){})
                .catch(function(error) {
                    this.errored = true;
            });
        },

        },

        mounted() {
            this.token = $("input[name='_token']").val();
            this.loadData();
            document.getElementById("success_alert").style.display = "none";
        },

        watch: {
            mute(val) {
                document.getElementById('mute').className = val ? "on" : "";
                document.getElementById('mute').innerHTML = val ? '<div class="loader"></div>' : '';
            },

            total(val) {
                this.resetPageNumbers();
            }
        },
    }
</script>

<style scoped>
    input[type=text]::-ms-clear {  display: none; width : 0; height: 0; }
    input[type=text]::-ms-reveal {  display: none; width : 0; height: 0; }
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration { display: none; }

    /* li.page-item {cursor: pointer;} */

    input[type="checkbox"] {
        height: 15px;
        width: 15px;
    }

    #search {
        width: 185px;
        height: 34px;
    }

    #showcount {
        height: 34px;
    }

    table thead th {
        vertical-align: middle;
    }

    td > input {
        min-width: 230px;
    }
</style>
