<template>
    <div>
        <div
            class="modal-backdrop"
            @keyup.esc.stop.prevent="close()"
            @keyup.enter.stop.prevent="save()"
        >
            <div class="modal modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-light p-3">
                        <slot name="header">
                            <h5 class="modal-title text-muted" id="modalCenterTitle">{{title}}</h5>
                            <button type="button" class="close noHover p-0 m-0" data-dismiss="modal" aria-label="Close" @click="close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </slot>
                    </div>
                    <div class="modal-body">
                        <slot name="body">
                            <div v-show="errors.length">
                                <b>Пожалуйста, исправьте указанные ошибки:</b>
                                <ul>
                                    <li v-for="(error, index) in errors" :key="'e-' + index">{{ error }}</li>
                                </ul>
                            </div>
                            <p class="align-middle school_name p-2">
                                {{ data.length > 0 ? data[0].governmentName : '' }}
                            </p>

                            <input
                                class="form-control modal-name align-middle mt-3"
                                id = "name"
                                name = "name"
                                ref="name"
                                type="text"
                                placeholder="Ф.И.О."
                                title="Имя пользователя"
                                v-model="fields.name"
                                :required = "true"
                            />
                            <span class="form-control alert-danger mt-2" v-if="messages.name.length > 0">
                                {{messages.name}}
                            </span>

                            <input
                                class="form-control modal-email align-middle mt-3"
                                id = "email"
                                name = "email"
                                type="text"
                                placeholder="e-mail адрес"
                                title="Адрес электронной почты пользователя"
                                v-model="fields.email"
                                :required = "true"
                            />
                            <span class="form-control alert-danger mt-2" v-if="messages.email.length > 0">
                                {{messages.email}}
                            </span>

                            <input
                                class="form-control modal-password align-middle mt-3"
                                id = "password"
                                name = "password"
                                type="password"
                                placeholder="Введите пароль"
                                title="Пароль пользователя"
                                v-model="fields.password"
                                :required = "true"
                            />
                            <span class="form-control alert-danger mt-2" v-if="!!messages.password">
                                {{messages.password}}
                            </span>
                        </slot>
                    </div>
                    <div class="modal-footer bg-light p-2">
                        <slot name="footer">
                            <button type="button" class="btn btn-secondary m-0" data-dismiss="modal" @click="close">Отмена</button>
                            <button type="button" class="btn btn-primary" @click="save">Сохранить</button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "EduDepartmentModal",
        props: {
            data: {
                type: Array,
                required: false,
                default: null,
            },
            defaultValue: {
                type: String,
                required: false,
                default: ""
            },
            title: {
                type: String,
                default: null
            },
            footer: {
                default: ""
            },
        },

        data: function () {
            return {
                errors: [],
                fields : {},
                messages: {
                    email: '',
                    name: '',
                    password: ''
                },

            }
        },

        methods: {
            save() {
                if (!this.validateName()) {
                    return;
                };

                if (!this.validateEmail()) {
                    return;
                };

                if (!this.validatePassword()) {
                    return;
                };

                this.updateData();

                this.fields = {}
            },

            close() {
                this.$emit('close');
                this.fields = {};
                this.messages = {email: '', name: '', password: ''};
            },


            isNotEdited(a, b) {
                return (a.name === b.name) && (a.email === b.email) && (a.password === b.password);
            },

            updateData() {
                let flag = this.isNotEdited(this.data[0], this.fields);
                if (flag) {
                    this.data[0].isNotEdited = flag
                    this.close();
                } else {
                    this.data[0].name = this.fields.name
                        .replace(/[&<>"']/gm, '')
                        .replace(/\s\s+/gm, " ")
                        .trim();
                    this.data[0].email = this.fields.email
                        .replace(/\s/gm, "")
                        .trim();
                    this.data[0].password = this.fields.password.trim();
                    this.$emit('save');
                }
            },

            setValues() {
                for (const [key, value] of Object.entries(this.data[0])) {
                    this.fields[key] = value;
                }
            },

            focusInput() {
                this.$refs.name.focus();
            },

            validateEmail() {
                const regexEmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
                let isNotValidated = !!this.fields.email ? !regexEmail.test(this.fields.email.replace(/\s+/g, "").trim()) : true;
                if (isNotValidated) {
                    this.messages.email = "Пожалуйста, введите действительный e-mail адрес";
                    return false;
                }
                else {
                    this.messages.email = '';
                    return true;
                }
            },

            validateName() {
                if (!this.fields.name) {
                    this.messages.name = "Пожалуйста, введите имя пользователя";
                    return false;
                }
                else {
                    this.messages.name = '';
                    return true;
                }
            },

            validatePassword() {
                let passwordLength = !!this.fields.password ? this.fields.password.length : 0;
                if (passwordLength < 6) {
                    this.messages.password = "Введите пароль длиной не менее 6 символов";
                    return false;
                }
                else {
                    this.messages.password = '';
                    return true;
                }
            },
        },

        watch: {
            data() {
                if (this.data.length > 0) {
                    this.setValues();
                    this.focusInput();
                }
            },
        },

        computed: {}
    }
</script>

<style scoped>
    .school_name {
        font-size: 18px;
        background-color: rgba(104, 100, 100, 0.1);
        border-radius: 5px;
        opacity: 0.7;
        text-align: center;
    }

    select {
        width: auto;
        text-align-last:center;
    }
</style>
