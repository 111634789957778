<template>
    <div>
        <div class="modal-backdrop">
            <div class="modal modal-dialog-scrollable modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-light p-3">
                        <slot name="header">
                            <h5 class="modal-title" id="modalCenterTitle">{{title}}</h5>
                            <button type="button" class="close px-2 py-1 m-0" data-dismiss="modal" aria-label="Close" @click="close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </slot>
                    </div>
                    <div class="modal-body">
                        <slot name="body">
                            <div class="alert alert-warning" v-if="errors.length">
                                <b>Пожалуйста, исправьте указанные ошибки:</b>
                                <ul>
                                    <li v-for="(error, index) in errors" :key="'e-' + index">{{ error }}</li>
                                </ul>
                            </div>
                            <table class="table table-striped table-bordered table-hover" id="pupilData">
                                <tbody>
                                    <tr>
                                        <th class="align-middle">Наименование ОО</th>
                                        <td colspan="3">
                                            <select class="form-control align-middle" id="school" v-model="schoolCode" :disabled="!!olympiad ? !olympiad.isInitialUploadActive: false">
                                                <option v-for="obj in schools" :value="Number(obj.code)" :key="obj.code" :selected="obj.code==data['Код ОО']" :disabled="obj.code==data['Код ОО']">{{obj.code}} {{obj.short_name}}</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr v-show="fields.subjectName === 'технология'">
                                      <th class="align-middle">Направление</th>
                                      <td class="align-middle">
                                          <select
                                              class="form-control align-middle" id="direction-type"
                                              v-model="fields['Направление']" :disabled="!!olympiad ? !olympiad.isInitialUploadActive: false"
                                              @change="setDirectionType"
                                          >
                                              <option :value="data['Направление']" selected hidden>{{ data['Направление'] }}</option>
                                              <option v-for="keyName in Object.keys(technologyDirections)" :value="keyName">{{ keyName }}</option>
                                          </select>
                                      </td>
                                      <th class="align-middle">Виды практик</th>
                                      <td class="align-middle">
                                        <select
                                            class="form-control align-middle" id="action-type"
                                            v-model="fields['Виды практик']" :disabled="!!olympiad ? !olympiad.isInitialUploadActive: false"
                                        >
                                          <option :value="data['Виды практик']" selected hidden>{{ data['Виды практик'] }}</option>
                                          <option v-for="val in technologyDirections[directionType]" :value="val">{{ val }}</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                        <th class="align-middle">Фамилия <span class="text-danger">*</span></th>
                                        <td class="align-middle text-center">
                                            <input
                                                    class="form-control align-middle"
                                                    id = "surname"
                                                    name = "surname" type="text"
                                                    v-model="fields['Фамилия']"
                                                    :required = "true"
                                                    :disabled="!!olympiad ? !olympiad.isInitialUploadActive: false"
                                            />
                                        </td>
                                        <th class="align-middle">Имя <span class="text-danger">*</span></th>
                                        <td class="align-middle text-center">
                                            <input
                                                class="form-control align-middle"
                                                id = "name" type="text"
                                                v-model="fields['Имя']"
                                                :required = "true"
                                                :disabled="!!olympiad ? !olympiad.isInitialUploadActive: false"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="align-middle">Отчество (при наличии)</th>
                                        <td class="align-middle text-center">
                                            <input
                                                class="form-control align-middle"
                                                id = "patronymic" type="text"
                                                v-model="fields['Отчество']"
                                                :disabled="!!olympiad ? !olympiad.isInitialUploadActive: false"
                                            />
                                        </td>
                                        <th>Дата рождения <span class="text-danger">*</span></th>
                                        <td>
                                            <input
                                                class="form-control align-middle"
                                                id = "birth-date" type="date"
                                                v-model="fields['Дата рождения']"
                                                :required = "true"
                                                :disabled="!!olympiad ? !olympiad.isInitialUploadActive: false"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="align-middle">Пол <span class="text-danger">*</span></th>
                                        <td>
                                            <select
                                                class="form-control align-middle" id="gender"
                                                v-model="fields['Пол']" :disabled="!!olympiad ? !olympiad.isInitialUploadActive: false"
                                            >
                                                <option :value="data['Пол']" selected hidden>{{ data['Пол'] }}</option>
                                                <option :value="'Ж'">Ж</option>
                                                <option :value="'М'">М</option>
                                            </select>
                                        </td>
                                        <th class="align-middle">Участник с ОВЗ</th>
                                        <td>
                                            <select
                                                class="form-control align-middle" id="ovz"
                                                v-model="fields['Участник с ОВЗ (да/нет)']"
                                            >
                                                <option v-if="!data['Участник с ОВЗ (да/нет)']" :value="'нет'" selected hidden>нет</option>
                                                <option v-else selected hidden :value="data['Участник с ОВЗ (да/нет)']">{{ data['Участник с ОВЗ (да/нет)'] }}</option>
                                                <option :value="'да'">да</option>
                                                <option :value="'нет'">нет</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="align-middle">Класс обучения <span class="text-danger">*</span></th>
                                        <td>
                                            <select
                                                class="form-control align-middle" id="edu_grade"
                                                v-model="fields['Класс обучения']" :disabled="!!olympiad ? !olympiad.isInitialUploadActive: false"
                                            >
                                                <option v-if="!data['Класс обучения']" selected hidden></option>
                                                <option v-else :value="data['Класс обучения']" selected hidden>{{data['Класс обучения']}} класс</option>
                                                <option v-for="i in (1, 11)" :key="'edu_grade_' + i" :value="i">{{i}} класс</option>
                                            </select>
                                        </td>
                                        <th class="align-middle">Класс участия <span class="text-danger">*</span></th>
                                        <td>
                                            <select
                                                class="form-control align-middle" id="part_grade"
                                                v-model="fields['Класс участия']" :disabled="!!olympiad ? !olympiad.isInitialUploadActive: false"
                                            >
                                                <option v-if="!data['Класс участия']" selected hidden></option>
                                                <option v-else :value="data['Класс участия']" selected hidden>{{data['Класс участия']}} класс</option>
                                                <option v-for="i in 11" :key="'part_grade_' + i" :value="i">{{i}} класс</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>

                                    </tr>
                                    <tr>
                                        <th class="align-middle">Балл  <span class="text-danger">*</span></th>
                                        <td class="align-middle text-center">
                                            <input
                                                class="form-control align-middle"
                                                id = "mark" type="text"
                                                pattern="[0-9]+([,][0-9]{1,2})?"
                                                v-model="fields['Балл']"
                                                :required = "true"
                                                :disabled="!!olympiad ? !olympiad.isInitialUploadActive: false"
                                            />
                                        </td>
                                        <th class="align-middle">Добавлено баллов по итогам апелляции</th>
                                        <td class="align-middle text-center">
                                            <input
                                                class="form-control align-middle"
                                                id = "add-mark" type="text"
                                                pattern="[0-9]+([,][0-9]{1,2})?"
                                                v-model="appealMark"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="align-middle">Итоговый балл</th>
                                        <td class="align-middle text-center">
                                            <span style="color: #09A0C8;font-weight: 600;">Вычисляется при сохранении</span>
                                        </td>
                                        <th class="align-middle">Максимальный балл</th>
                                        <td class="align-middle text-center">
                                            <input
                                                class="form-control align-middle"
                                                id = "max-mark" type="text"
                                                pattern="[0-9]+([,][0-9]{1,2})?"
                                                v-model="fields['Максимальный балл']"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="align-middle">Статус  <span class="text-danger">*</span></th>
                                        <td>
                                            <select
                                                class="form-control align-middle" id="status"
                                                v-model="fields['Статус']"
                                            >
                                                <option v-if="!data['Статус']" selected hidden :value="'Участник'">Участник</option>
                                                <option v-else selected hidden :value="data['Статус']">{{data['Статус']}}</option>
                                                <option :value="'участник'">участник</option>
                                                <option :value="'призёр'">призёр</option>
                                                <option :value="'победитель'">победитель</option>
                                            </select>
                                        </td>
                                        <th class="align-middle">Призёр МЭ прошлого года <span class="text-danger">*</span></th>
                                        <td>
                                            <select
                                                class="form-control align-middle" id="last-year-prizer"
                                                v-model="fields['Призёр республиканского этапа прошлого года']"
                                            >
                                                <option v-if="!data['Призёр республиканского этапа прошлого года']" :value="'нет'" selected hidden>нет</option>
                                                <option v-else :value="data['Призёр республиканского этапа прошлого года']" selected hidden>{{data['Призёр республиканского этапа прошлого года']}}</option>
                                                <option :value="'да'">да</option>
                                                <option :value="'нет'">нет</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="align-middle">ФИО учителя</th>
                                        <td class="align-middle text-center">
                                            <input
                                                class="form-control align-middle"
                                                id = "teacher-name" type="text"
                                                v-model="fields['ФИО учителя']"
                                            />
                                        </td>
                                        <th class="align-middle">Место работы учителя</th>
                                        <td class="align-middle text-center">
                                            <textarea  class="form-control align-middle"
                                                name="work-place" id="work-place" cols="25" rows="3"
                                                v-model="fields['Место работы учителя']"
                                            ></textarea>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </slot>
                    </div>
                    <div class="modal-footer bg-light p-2">
                        <slot name="footer"></slot>
                        <button type="button" class="btn btn-secondary m-0" data-dismiss="modal" @click="close">Отмена</button>
                        <button v-if="modalRole == 1" type="button" class="btn btn-success" @click="update">Применить</button>
                        <button v-else-if="modalRole == 2" type="button" class="btn btn-primary" @click="create">Сохранить</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "ProtocolImportModal",
        props: {
            data: {
                type: Object,
                required: false,
                default: {}
            },

            schools: {
                type: Array,
                required: false,
                default: []
            },

          technologyDirections: {
              type: Object,
              required: false,
              default: {}
          },

            title: {
                type: String,
                default: null
            },

            footer: {
                default: "* - обязательные поля"
            },

            modalRole: {
                type: Number,
                required: true,
                default: 0
            },

            clickCount: {
                type: Number,
                required: true,
                default: 0
            },
            
            olympiad: {
                type: Object,
                required: false,
                default: {}
            }

        },

        data: function () {
            return {
                appealMark: null,
                directionType: '',
                errors: [],
                fields : {},
                isObjEdited: false,
                invalidValueClass: 'wrong-value-in-field',
                partGradeStartAt: null,
                regExp: /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])/, // 2020-01-25
                regExpRu: /([0-9]|0[1-9]|[1-2][0-9]|3[0-1]).([0-9]|0[1-9]|1[0-2]).[0-9]{4}/, // 21.12.2021
                schoolCode: 0
            }
        },

        methods: {
            create() {
                this.updateObjectEntries();
                this.checkForm();
                if (this.errors.length === 0) {
                    this.$emit('create');
                    this.close();
                };
            },

            close() {
                this.fields = {};
                this.schoolCode = null;
                this.appealMark = null;
                this.$emit('close');
            },

            update() {
                this.updateObjectEntries();
                this.checkForm();
                if (this.errors.length === 0) {
                    this.$emit('update');
                    this.close();
                }
            },

            updateObjectEntries() {
                this.checkifObjEdited(this.data, this.fields);
                if (this.isObjEdited) {
                    for (let key in this.fields) {
                        this.data[key] = this.fields[key];
                    }
                    if (this.data['Код ОО'] != this.fields['Код ОО']) {
                        let school = this.schools.find(school => school.code == this.fields['Код ОО']);
                        this.data['Наименование ОО'] = school.name;
                    }
                    let date = this.data['Дата рождения'];
                    if (date.match(this.regExp)) {
                        this.data['Дата рождения'] = moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');
                    }
                    this.data.isEdited = this.isObjEdited;
                }
            },

            checkifObjEdited(a, b) {
                for (let key in a) {
                    if (this.isObjEdited = (a[key] !== b[key])) return;
                }
            },

            checkForm() {
                this.errors = [];

                const formData = [
                    {id: 'surname', index: 'Фамилия', errorText: 'Требуется указать фамилию.'},
                    {id: 'name', index: 'Имя', errorText: 'Требуется указать имя.'},
                    {id: 'birth-date', index: 'Дата рождения', errorText: 'Требуется указать дату рождения.'},
                    {id: 'gender', index: 'Пол', errorText: 'Требуется указать пол.'},
                    {id: 'edu_grade', index: 'Класс обучения', errorText: 'Требуется указать класс обучения.'},
                    {id: 'part_grade', index: 'Класс участия', errorText: 'Требуется указать класс участия.'},
                    {id: 'mark', index: 'Балл', errorText: 'Требуется указать класс балл.'},
                    {id: 'status', index: 'Статус', errorText: 'Требуется указать статус.'},
                    {id: 'last-year-prizer', index: 'Призёр республиканского этапа прошлого года', errorText: 'Призёр республиканского этапа прошлого года.'},
                ]

                formData.forEach(element => {
                    let inputField = document.getElementById(element.id);
                    if (!this.fields[element.index]) {
                    inputField.classList.add(this.invalidValueClass);
                    this.errors.push(element.errorText);
                    } else {
                        inputField.classList.contains(this.invalidValueClass) ? inputField.classList.remove(this.invalidValueClass) : false;
                    }
                });

                let inputField = document.getElementById('mark');
                let mark = this.fields['Балл'];
                mark = !!mark ? mark.replace(/,/gi, '.') : 0;
                if (isNaN(mark)) {
                    inputField.classList.add(this.invalidValueClass);
                    this.errors.push('Требуется указать балл - числовое значение.');
                } else {
                    inputField.classList.contains(this.invalidValueClass) ? inputField.classList.remove(this.invalidValueClass) : false;
                }

                inputField = document.getElementById('add-mark');
                mark = this.fields['Добавлено баллов по итогам апелляций'];
                if (!!mark) {
                    mark = mark.toString().replace(/,/gi, '.');
                    if (isNaN(mark)) {
                        inputField.classList.add(this.invalidValueClass);
                        this.errors.push('Требуется указать добавленный балл - числовое значение.');
                    } else {
                        inputField.classList.contains(this.invalidValueClass) ? inputField.classList.remove(this.invalidValueClass) : false;
                    }
                }
            },

            removeClassFromInputFields(element, className) {
                let fields = document.querySelectorAll(element);
                fields.forEach(
                    element => element.classList.contains(className) ?
                    element.classList.remove(className) : false
                );
            },

            setDirectionType() {
              this.directionType = this.fields['Направление'];
            },
        },

        watch: {
            clickCount(val) {
                this.removeClassFromInputFields('input', this.invalidValueClass);
                this.removeClassFromInputFields('select', this.invalidValueClass);
                this.errors = [];
                if (this.modalRole === 1 && !!this.data) {
                    for (const [key, value] of Object.entries(this.data)) {
                        this.fields[key] = value;
                    }
                } else {
                    for (const [key, value] of Object.entries(this.data)) {
                        this.fields[key] = value;
                    }
                }
                this.schoolCode = Number(this.fields['Код ОО']);
                this.appealMark = this.fields['Добавлено баллов по итогам апелляций'];
            },

            directionType(val) {
              this.fields['Напрвление'] = val;
            },

            schoolCode(val) {
                if (!val) return;
                this.fields['Код ОО'] = val;
                let school = this.schools.find(school => school.code === val);
                this.fields['Наименование ОО'] = !!school.name ? school.name : '';
            },

            appealMark(val) {
                this.fields['Добавлено баллов по итогам апелляций'] = !!val ? val : null;
            }
        },

    }
</script>

<style scoped>

    th {
        width: 230px;
    }
</style>
