const funcslib = {
    isValueEmpty(value = null) {
        return !value ? 'empty_cell' : '';
    },

    isGradeNumeric(value) {
        if (!value) return 'empty_cell';
        let strValue = value.toString().replace('.', '..');
        let floatValue = parseFloat(value);
        return strValue.length !== floatValue.toString().length ? 'incorrect_value' : '';
    },

    isMarkNumeric(value = null) {
        if (!value && value != 0*1) return 'empty_cell';
        return value != 0*1 && !Number(value.toString().replace(',', '.')) ? 'incorrect_value' : '';
    },

    isDateCorrect(value) {
        if (!value) return 'empty_cell';
        return moment(value, 'DD.MM.YYYY').format('YYYY-MM-DD') === 'Invalid date' ? 'incorrect_value' : '';
    },

    checkGender(value) {
        if (!value) return 'empty_cell';
        return !['ж', 'Ж', 'м', 'М'].includes(value) ? 'incorrect_value' : '';
    },

    showNumericValue(value) {
        if (value !== '' && value !== null && typeof Number(value) === 'number') {
            return value * 1 == 0 ? '0' : value.toString().replace('.', ',');
        } else return '';
    },

    getTechnologyDirections() {
        return {
            "Техника, технология и техническое творчество": [
                "Ручная обработка древесины",
                "Ручная обработка металла",
                "Механическая обработка древесины",
                "Механическая обработка металла",
                "Электротехника",
                "Робототехника",
                "3d – моделирование и печать",
                "Обработка материалов на лазерно-гравировальной машине",
                "Промышленный дизайн",
            ],
            "Культура дома, дизайн и технологии": [
                "Обработка швейного изделия или узла + моделирование швейных изделий",
                "Обработка швейного изделия или узла + моделирование швейных изделий с использованием графических редакторов",
                "Обработка швейного изделия или узла на швейно-вышивальном оборудовании + моделирование швейных изделий",
                "Обработка швейного изделия или узла на швейно-вышивальном оборудовании +  моделирование швейных изделий с использованием графических редакторов",
                "Робототехника",
                "3d – моделирование и печать",
                "Обработка материалов на лазерно-гравировальной машине",
                "Промышленный дизайн"
            ],
            "Роботехника": ["-"],
            "Информационная безопасность": ["-"],
        }
    },

    isStringInArray(string = '', arrayOfStrings = []) {
        string = string.toLowerCase();
        for (i = 0; i < arrayOfStrings.length; i++) {
            arrayOfStrings[i] = arrayOfStrings[i].toLowerCase();
        }
        return !arrayOfStrings.includes(string) ? 'incorrect_value' : '';
    }
}

export default funcslib;