<template>
    <div class="container">
        <div id="mute"></div>
        <div class="row">
            <div class="col">
                <label class="row text-black-50 font-weight-bolder" for="full-name">Полное наименование по уставу:</label>
                <textarea class="form-control text-dark" id="full-name" v-if="editing" v-model="gridData[0]['name']" ></textarea>
                <h5 class="text-dark" v-else>{{gridData[0].name}}</h5>
                <label class="row text-black-50 font-weight-bolder" for="short-name">Краткое наименование по уставу:</label>
                <textarea class="form-control text-dark" id="short-name" v-if="editing" v-model="gridData[0].short_name"></textarea>
                <h5 class="text-dark" v-else>{{gridData[0].short_name}}</h5>
                <label class="row text-black-50 font-weight-bolder"></label>
                <div class="col mt-4" id="eduprogram">
                    <input type="radio" id="program1" name="age" value="1" v-model.number="gridData[0].edu_program" :disabled="!editing">
                    <label for="program1" class="text-dark">Начальная школа</label><br>
                    <input type="radio" id="program2" name="age" value="2" v-model.number="gridData[0].edu_program" :disabled="!editing">
                    <label for="program2" class="text-dark">Основная школа</label><br>
                    <input type="radio" id="program3" name="age" value="3" v-model.number="gridData[0].edu_program" :disabled="!editing">
                    <label for="program3" class="text-dark">Средняя школа, гимназия, лицей</label>
                </div>

                <div class="mt-4">
                    <button class="btn btn-primary" v-show="!editing" @click="editing = !editing">Изменить</button>
                    <button  class="btn btn-success" v-show="editing" @click="updateProfile(), editing = !editing">Сохранить</button>
                    <button  class="btn btn-outline-secondary" v-show="editing" @click="getData(), editing = !editing">Отменить</button>
                </div>
            </div>
            <div class="col">
                <label class="row text-black-50 font-weight-bolder">Количество обучащихся в школе</label>
                <table class="table table-bordered table-striped table-hover table-sm mb-3" id="gendercount">
                    <thead>
                    <tr>
                        <th class="align-middle text-center">Класс</th>
                        <th class="align-middle text-center">Количество девочек</th>
                        <th class="align-middle text-center">Количество мальчиков</th>
                        <th class="align-middle text-center" id="countingrade"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="i in (1, 11)" :key="'tr' + i">
                        <th class="align-middle text-center">{{i}}</th>
                        <td class="align-middle text-center">
                            <label v-if="editing" class="m-auto">
                                <input v-if="editing && i <= 9" class="form-control align-middle text-center" type="number"
                                       oninput="javascript: if (value.length > maxLength) value = value.slice(0, maxLength);"
                                       maxlength="3"  v-model.number="gridData[0]['grade0' + i + 'f']"
                                />
                                <input v-if="editing && i > 9" class="form-control align-middle text-center" type="number"
                                       oninput="javascript: if (value.length > maxLength) value = value.slice(0, maxLength);"
                                       maxlength="3"  v-model.number="gridData[0]['grade' + i + 'f']"
                                />
                            </label>
                            <span v-if="!editing && i <= 9">{{gridData[0]['grade0' + i + 'f']}}</span>
                            <span v-else-if="!editing && i > 9">{{gridData[0]['grade' + i + 'f']}}</span>
                        </td>
                        <td class="align-middle text-center">
                            <label v-if="editing" class="m-auto">
                                <input v-if="editing && i <= 9" class="form-control align-middle text-center" type="number"
                                       oninput="javascript: if (value.length > maxLength) value = value.slice(0, maxLength);"
                                       maxlength="3"  v-model.number="gridData[0]['grade0' + i + 'm']"
                                />
                                <input v-if="editing && i > 9" class="form-control align-middle text-center" type="number"
                                       oninput="javascript: if (value.length > maxLength) value = value.slice(0, maxLength);"
                                       maxlength="3"  v-model.number="gridData[0]['grade' + i + 'm']"
                                />
                            </label>
                            <span v-if="!editing && i <= 9">{{gridData[0]['grade0' + i + 'm']}}</span>
                            <span v-else-if="!editing  && i > 9">{{gridData[0]['grade' + i + 'm']}}</span>
                        </td>
                        <th v-if="i <= 9" class="align-middle text-center">{{Number(gridData[0]['grade0' + i + 'f']) + Number(gridData[0]['grade0' + i + 'm'])}}</th>
                        <th v-else class="align-middle text-center">{{Number(gridData[0]['grade' + i + 'f']) + Number(gridData[0]['grade' + i + 'm'])}}</th>
                    </tr>

                    <tr>
                        <th class="align-middle text-center">Итого:</th>
                        <th class="align-middle text-center">{{gridData[0].grade01f + gridData[0].grade02f + gridData[0].grade03f + gridData[0].grade04f + gridData[0].grade05f +gridData[0].grade06f +gridData[0].grade07f +gridData[0].grade08f + gridData[0].grade09f + gridData[0].grade10f + gridData[0].grade11f}}</th>
                        <th class="align-middle text-center">{{gridData[0].grade01m + gridData[0].grade02m + gridData[0].grade03m + gridData[0].grade04m + gridData[0].grade05m +gridData[0].grade06m +gridData[0].grade07m +gridData[0].grade08m + gridData[0].grade09m + gridData[0].grade10m + gridData[0].grade11m}}</th>
                        <th class="align-middle text-center">
                            {{gridData[0].grade01f + gridData[0].grade02f + gridData[0].grade03f + gridData[0].grade04f + gridData[0].grade05f +gridData[0].grade06f +gridData[0].grade07f +gridData[0].grade08f + gridData[0].grade09f + gridData[0].grade10f + gridData[0].grade11f +
                            gridData[0].grade01m + gridData[0].grade02m + gridData[0].grade03m + gridData[0].grade04m + gridData[0].grade05m +gridData[0].grade06m +gridData[0].grade07m +gridData[0].grade08m + gridData[0].grade09m + gridData[0].grade10m + gridData[0].grade11m}}
                        </th>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <section v-if="errored" class="alert alert-danger alert-dismissible alert-important mt-3" role="alert"
                 id="failed_alert">
            <span id="failed_text">К сожалению, в данный момент мы не можем получить эту информацию, пожалуйста, попробуйте вернуться
                позже.</span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true" style="color: red; font-weight: 600;">&times;</span>
            </button>
        </section>

        <section class="alert alert-success alert-dismissible alert-important mt-3" role="alert" id="success_alert">
            <span id="success_text"></span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true" style="color: red; font-weight: 600;">&times;</span>
            </button>
        </section>
    </div>
</template>

<script>
    export default {
        name: "SchoolProfile",

        data: function () {
            return {
                mute: false,
                gridData: [{"name": '', "short_name": ''}],
                editing: false,
                errored: false,
                token: null
            }
        },

        methods: {
            async getData () {
                this.mute = true;
                await fetch('/api/school-profile-data')
                .then(res => res.json())
                .then(json => {
                    this.gridData = json;
                })
                .catch(error => {
                    this.errored = true;
                });
                this.mute = false;
            },

            async updateProfile() {
                this.mute = true;
                await fetch('/school-profile', {
                    headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json, text-plain, */*",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-CSRF-TOKEN": this.token
                    },
                    method: 'post',
                    credentials: "same-origin",
                    body: JSON.stringify( this.gridData[0] )
                })
                .then(response => response.json())
                .then(result => {
                    this.stored = true;
                    this.getData();
                    document.getElementById("success_alert").style.display = "block";
                    document.getElementById("success_text").innerText = result.message;
                    this.timer(5);
                })
                .catch(function(error) {
                    this.errored = true;
                });
                this.mute = false;
                await this.reloadPage();
            },

            resolveAfter(ms = 3000) {
                return new Promise(resolve => {
                setTimeout(() => {
                resolve();
                }, ms);
            });
            },

            async reloadPage(ms = 2500) {
                await this.resolveAfter(ms);
                document.location.reload();
            },

            timer(limit = 3) {
                let seconds = 0;
                let intervalId = setInterval(() => {
                    seconds++;
                    if (seconds > limit) {
                        clearInterval(intervalId);
                        document.getElementById("success_text").innerText = "";
                        document.getElementById("success_alert").style.display = "none";
                    }
                }, 1000);
            },
        },

        mounted() {
            this.token = $("input[name='_token']").val();
            this.getData();
            document.getElementById("success_alert").style.display = "none"
        },

        watch: {
            mute(val) {
                document.getElementById('mute').className = val ? "on" : "";
                document.getElementById('mute').innerHTML = val ? '<div class="loader"></div>' : '';
            }
        }
    }
</script>

<style scoped>
    table->input {
        width: 100px;
        height: 30px;
        margin: 0;
    }
    td {
        padding: 1px 1px 1px 1px;
    }
    h5, #eduprogram {
        font-size: 16px;
        border: 1px solid #ddd;
        border-radius: 10px 0;
        padding: 10px 10px 10px 10px;
        width: 96%;
    }

</style>
