<template>
  <div class="container container-fluid" id="body-container">
    <div id="mute"></div>
    <div class="alert alert-danger" :class="isSaved ? 'd-none' : 'd-block'" id="alert-not-saved">
      <span>Данные еще не отправлены на сервер!<br>Не забудьте нажать кнопку "Отправить"!</span>
    </div>

    <div class="text-center">
      <a href="/files/protocol_MUNICIPAL.xlsx" download>
        <i class="fas fa-file-excel"></i>
        Скачать шаблон протокола МЭ
        <i class="fas fa-file-excel"></i>
      </a>
      <span class="text-info">(кроме технологии)</span>
    </div>
    <div class="text-center mb-3">
      <a href="/files/technology_MUNICIPAL.xlsx" download>
        <i class="fas fa-file-excel"></i>
        Скачать шаблон протокола по технологии МЭ
        <i class="fas fa-file-excel"></i>
        <span class="text-info">(только для технологии)</span>
      </a>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <select class="form-control border bg-white" id="showsubject" @change="selectSubject()" v-model="olympiadId">
        <option :value="null" disabled selected hidden>Выберите олимпиаду</option>
        <option v-for="subject in subjects" v-bind:value = "subject.olympiad_id" :key="subject.name + subject.olympiad_date">{{ dateFormat(subject.olympiad_date) }} - {{ subject.name }}</option>
      </select>
      <button class="btn btn-outline-secondary ml-1" title="Добавить новую запись" @click="showModal()" :disabled="!olympiadId || !isSaved || olympiad.isOlympiadFinished || !olympiad.isInitialUploadActive"><i class="fas fa-plus"></i> Добавить нового участника</button>
      <button class="btn btn-outline-secondary ml-1" title="Выгрузка сохранённого протокола" @click="saveToExcel()" :disabled="dbData.length === 0"><i class="fas fa-download"></i> Контрольная выгрузка</button>
    </div>

    <div class="d-flex justify-content-center">
      <div class="row align-items-center mt-3 px-3" id="list-upload">
        <label for="file-input"></label>
        <input
            type="file" id="file-input" ref="file" accept=".xls, .xlsx, .ods"
            @click="setDefaults"
            :class="{ ' is-invalid' : error.message }"
            :disabled="!olympiadId || dbData.length > 0 || olympiad.isOlympiadFinished || !olympiad.isInitialUploadActive"
        />

        <div class="m-0 p-0 ml-2" :hidden="hidden">
          <button
              class="btn btn-outline-secondary"
              :hidden="!hideSave"
              @click="readData"
          >
            Открыть
          </button>
          <button
              class="btn btn-outline-success" id="submit"
              :hidden="hideSave"
              @click="store(fileData)"
          >
            Отправить
          </button>
          <button
              class="btn btn-outline-danger" id="cancel"
              @click="cancelImport"
          >
            Отмена
          </button>
        </div>
        <div v-if="error.message" class="invalid-feedback"></div>
      </div>
    </div>
    <hr>
    <div class="table-container">
      <div class="d-flex justify-content-between mx-4">
        <label class="m-0">Показать по
          <select class="rounded bg-white" name="per_page" v-model.number="pageSize" @change="onPageSizeChange" v-bind:value="pageSize">
            <option selected hidden>{{pageSize}}</option>
            <option v-for="option in options" :key="option">{{option}}</option>
          </select>
        </label>
        <div>
          Показать школу
          <input type="checkbox" name="school-info" id="school-info" v-model="showSchool">
        </div>
        <div>
          Показать данные учителя
          <input type="checkbox" name="teacher-info" id="teacher-info" v-model="showTeacher">
        </div>
        <div class="align-middle m-0 pt-1 float-right" v-if="total" >Всего: {{total}}</div>

        <div class="align-middle m-0 pt-1 float-right" v-else>Всего: 0 </div>
      </div>
      <table class="table table-bordered table-striped table-hover table-responsive-lg mt-3 mb-3">
        <thead>
        <tr>
          <th class="align-middle text-center" v-for="(item, index) in headerValues" :key="'th' + index"
              :hidden="[17, 18].includes(index) ? !showTeacher : [3, 4].includes(index) ? !showSchool : [1, 2].includes(index) ? !showTechnology :false"
          >
            <div>{{ item }}</div>
          </th>
        </tr>
        <tr>
          <th class="align-middle text-center" v-for="(item, index) in headerValues" :key="'th-num-' + index"
              :hidden="[17, 18].includes(index) ? !showTeacher : [3, 4].includes(index) ? !showSchool : [1, 2].includes(index) ? !showTechnology :false"
          >
            <div>{{ index + 1 }}</div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in pageData" :key="'tr' + index">
          <td class="align-middle text-center row-num">{{ index + 1 + pageSize * (current_page - 1) }}</td>
          <td class="align-middle" v-show="showTechnology" :class="funcslib.isStringInArray(item['Направление'], Object.keys(funcslib.getTechnologyDirections()))">{{item['Направление']}}</td>
          <td class="align-middle" v-show="showTechnology" :class="funcslib.isStringInArray(item['Виды практик'], funcslib.getTechnologyDirections()[item['Направление']])">{{item['Виды практик']}}</td>
          <td class="align-middle" v-show="showSchool">{{item['Код ОО']}}</td>
          <td class="align-middle" v-show="showSchool" style="word-break:break-all;">{{item['Наименование ОО']}}</td>
          <td class="align-middle">{{item['Фамилия']}} {{item['Имя']}} {{item['Отчество']}}</td>
          <td class="align-middle text-center" :class="funcslib.isDateCorrect(item['Дата рождения'])">{{item['Дата рождения']}}</td>
          <td class="align-middle text-center" :class="funcslib.checkGender(item['Пол'])">{{item['Пол']}}</td>
          <td class="align-middle text-center">{{item['Участник с ОВЗ (да/нет)']}}</td>
          <td class="align-middle text-center" :class="funcslib.isGradeNumeric(item['Класс обучения'])">{{item['Класс обучения']}}</td>
          <td class="align-middle text-center" :class="funcslib.isGradeNumeric(item['Класс участия'])">{{item['Класс участия']}}</td>
          <td class="align-middle text-center" :class="funcslib.isMarkNumeric(item['Балл'])">{{funcslib.showNumericValue(item['Балл'])}}</td>
          <td class="align-middle text-center">{{item['Добавлено баллов по итогам апелляций']}}</td>
          <td class="align-middle text-center">{{item['Итоговый балл']}}</td>
          <td class="align-middle text-center">{{item['Максимальный балл']}}</td>
          <td class="align-middle text-center" :class="!item['Статус'] ? 'empty_cell_bg' : ''" style="word-break:break-all;">{{item['Статус']}}</td>
          <td class="align-middle text-center" :class="!item['Призёр республиканского этапа прошлого года'] ? 'empty_cell' : ''">{{item['Призёр республиканского этапа прошлого года']}}</td>
          <td class="align-middle" :class="!item['ФИО учителя'] ? 'empty_cell' : ''" v-show="showTeacher" style="word-break:break-all;">{{item['ФИО учителя']}}</td>
          <td class="align-middle" :class="!item['Место работы учителя'] ? 'empty_cell' : ''" style="word-break:break-all;" v-show="showTeacher">{{item['Место работы учителя']}}</td>
          <td class="align-middle text-center">
            <button class="btn btn-outline-success mb-1" id="showModal" type="button" title="Изменить" @click="showModal(index)" :disabled="olympiad.isOlympiadFinished"><i class="fas fa-edit"></i></button>
            <button class="btn btn-outline-danger" id="delete" title="Удалить" @click="deleteTableRow(index)" :disabled="olympiad.isOlympiadFinished || !olympiad.isInitialUploadActive"><i class="far fa-trash-alt"></i></button>
          </td>
        </tr>
        <tr v-if="!!olympiadId">
          <button class="btn btn-outline-primary m-1 add-record" title="Добавить новую запись" @click="showModal()" :disabled="!isSaved || olympiad.isOlympiadFinished || !olympiad.isInitialUploadActive">
            <i class="fas fa-plus"></i>
          </button>
        </tr>
        </tbody>
      </table>
      <div class="row mb-2" id="gridFooter" v-show="pageData.length > 0">
        <div class="col-md d-flex justify-content-between align-items-center px-5">
          <div class="">Страница {{ current_page }} из {{ pages[pages.length - 1] }} </div>
          <nav aria-label="Page navigation">
            <ul class="pagination pagination-sm m-0">
              <li class="page-item" @click="paginate(fileData, pageSize, 1)"><a class="page-link"> &lt;&lt; </a></li>
              <li class="page-item">
                <a class="page-link" @click="previousPage"> &lt; </a>
              </li>
              <li class="page-item" v-for="page in pages"
                  v-show="page > current_page - 4 && page < current_page + 4"
                  v-bind:class="{'active': checkPage(page)}"
                  :key="page"
              >
                <a class="page-link" @click="paginate(fileData, pageSize, page)">{{ page }}</a>
              </li>
              <li class="page-item">
                <a class="page-link" @click="nextPage"> &gt; </a>
              </li>
              <li class="page-item" @click="paginate(fileData, pageSize, current_page = pages[pages.length - 1])"><a class="page-link"> &gt;&gt; </a></li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="alert alert-info" v-show="dbData.length > 0 && !olympiad.isOlympiadFinished && olympiad.isInitialUploadActive">
        <button class="btn btn-outline-danger mx-1" title="Полное удаление сохранённого протокола" @click="deleteWholeProtocol()">
          Удалить протокол
        </button>
        <span>Внимание!!! Выполняйте данное действие, только при необходимости полной замены протокола по предмету".</span>
      </div>
    </div>
    <section v-if="errored" class="alert alert-danger alert-dismissible alert-important mt-3" role="alert" id="failed_alert">
      <span id="failed_text">К сожалению, в данный момент мы не можем получить эту информацию, пожалуйста, попробуйте вернуться позже.</span>
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true" style="color: red; font-weight: 600;">&times;</span>
      </button>
    </section>

    <section class="alert alert-success alert-dismissible alert-important mt-3" role="alert" id="success_alert">
      <span id="success_text"></span>
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true" style="color: red; font-weight: 600;">&times;</span>
      </button>
    </section>

    <protocol-municipal-import-modal
        v-show="isModalVisible"
        :data = "selectedPageData"
        :title="modalTitle"
        :modalRole="modalRole"
        :clickCount="clickCount"
        :schools="schools"
        :olympiad="olympiad"
        :technologyDirections="funcslib.getTechnologyDirections()"
        @close="closeModal"
        @update="editTableRow"
        @create="store([selectedPageData])"
    />
  </div>
</template>
<script>
import funcslib from './common';
export default {
  props: {
    muteState: false,
  },
  data() {
    return {
      areaName: '',
      clickCount: 0,
      disableSave: true,
      dbData: [],
      error: {},
      errored: false,
      fileData: [],
      funcslib: funcslib,
      headerValues: [
        "№",
        "Направление",
        "Виды практик",
        "Код ОО",
        "Наименование ОО",
        "Фамилия Имя Отчество",
        "Дата рождения",
        "Пол",
        "Участник с ОВЗ (да/нет)",
        "Класс обучения",
        "Класс участия",
        "Балл",
        "Добавлено баллов по итогам апелляций",
        "Итоговый балл",
        "Максимальный балл",
        "Статус",
        "Призёр республиканского этапа прошлого года",
        "ФИО учителя",
        "Место работы учителя",
        " "
      ],
      hidden: true,
      hideSave: true,
      isDbRecord: false,
      isModalVisible: false,
      isOlympiadActive: false,
      isSaved: true,
      minutes: 0,
      modalRole: 0,
      modalTitle: '',
      mute: false,
      olympiad: null,
      olympiadId : null,
      rowIndex: null,
      selectedPageData: {},
      subjects: [],
      showSchool: true,
      showTeacher: true,
      showTechnology: false,
      current_page: 1,
      options: [5, 10, 15, 20, 30, 50, 100],
      pageData: [],
      pages: [],
      pageSize: 15,
      schools: [],
      token: null,
      total: null,
    }
  },
  methods: {
    selectSubject() {
      const olympiadSubject = document.getElementById('olympsubject');
      this.cancelImport();
      this.getDbData();
      this.olympiad = this.subjects.find(item => item.olympiad_id === this.olympiadId);
      if (!!this.olympiad) {
        let innerText = this.olympiad.isOlympiadFinished
            ? `Ввод данных по олимпиаде "${this.olympiad.name}" завершён `
            + this.dateFormat(this.olympiad.appeal_end)
            + ` в ${moment(this.olympiad.appeal_end, 'YYYY-MM-DD HH:mm').format('HH:mm')}`
            : this.olympiad.name + " (дата проведения: "
            + this.dateFormat(this.olympiad.olympiad_date)
            + `)\nЗагрузка первичного протокола закроется ${this.dateFormat(this.olympiad.initial_report)} в `
            + `${moment(this.olympiad.initial_report, 'YYYY-MM-DD HH:mm').format('HH:mm')}`
            + `\nВвод результатов аппеляций закроется ${this.dateFormat(this.olympiad.appeal_end)} в `
            + `${moment(this.olympiad.appeal_end, 'YYYY-MM-DD HH:mm').format('HH:mm')}`;

        let textColor = this.olympiad.isOlympiadFinished ? '#F06A07' : 'gray';

        olympiadSubject.innerText = innerText;
        olympiadSubject.style.color = textColor;
        document.getElementById('olympiad_info').classList.add('d-block');
      } else {
        document.getElementById('olympiad_info').classList.add('d-none');
      }
    },

    async getDbData() {
      let protocolData = null;
      this.fileData = [];
      this.mute = true;

      await fetch(`/api/get-protocol?olympiad_id=${this.olympiadId}`)
          .then(res => res.json())
          .then(json => protocolData = json)
          .catch(error => {
            this.errored = true;
          });
      this.dbData = protocolData['dataRows'];
      this.schools = protocolData['schools'];
      protocolData = null;
      this.mute = false;
      this.prepareDataToShow(this.fileData);
      this.resetPageNumbers();
      this.paginate(this.fileData, this.pageSize, 1);
    },

    readFileAsync(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      })
    },

    arrayBufferToString(arrayBuffer) {
      let data = new Uint8Array(arrayBuffer);
      let workbook = XLSX.read(data, {type: 'array'});
      let sheetName = workbook.SheetNames[0];
      let worksheet = workbook.Sheets[sheetName];
      worksheet = XLSX.utils.sheet_to_json(worksheet);
      worksheet.forEach(obj => {
        let tmpObj = {
          '№': 1, 'Направление': '', 'Виды практик' :'', 'Код ОО': null, 'Наименование ОО': '',
          'Фамилия': '', 'Имя': '', 'Отчество': '-', 'Дата рождения': '',
          'Пол': '', 'Участник с ОВЗ (да/нет)': 'нет', 'Класс обучения': null,
          'Класс участия': null, 'Балл': null, 'Добавлено баллов по итогам апелляций': null,
          'Итоговый балл': null,  'Максимальный балл': null, 'Статус': '',
          'Призёр республиканского этапа прошлого года': 'нет',
          'ФИО учителя': '', 'Место работы учителя': '',
          isEdited: false
        };

        for (const [key, value] of Object.entries(obj)) {
          if (typeof value === 'string') {
            Object.defineProperty(obj, key, {
              value: value.trim().replace('\n', ' ').replace('  ', ' '),
            });
          }
          if (typeof value === 'number') {
            Object.defineProperty(obj, key, {
              value: value.toString().replace('.', ','),
            });
          }
          if (key === 'Дата рождения') {
            if (typeof value === 'number') {
              let birthDate = new Date(Math.round((value - 25569)*86400*1000));
              let options = {
                year: "numeric",
                month: "2-digit",
                day: "2-digit"
              };
              Object.defineProperty(obj, key, {
                value: birthDate.toLocaleDateString("ru", options),
              });
            }
          }
          tmpObj[key] = obj[key];
        }
        // let schoolName = this.schools.find(element => element.code == obj['Код ОО']).name;
        // tmpObj["Наименование ОО"] = !!schoolName && schoolName > -1 ? schoolName : 'школа не найдена';
        tmpObj["Итоговый балл"] = this.finalMark(tmpObj);
        this.fileData.push(tmpObj);
      });
      worksheet = [];
      this.total = this.fileData.length;
      this.resetPageNumbers();
      this.paginate(this.fileData, this.pageSize, 1);
      this.isSaved = false;
    },

    async processFile(file) {
      const timer = ms => new Promise(res => setTimeout(res, ms));
      this.mute = true;
      await timer(1500);
      try {
        let arrayBuffer = await this.readFileAsync(file);
        this.arrayBufferToString(arrayBuffer);
      } catch (error) {
      }
      this.mute = false;
      this.schoolCodeNotExists();
    },

    async readData() {
      if (!this.olympiad.isInitialUploadActive) {
        alert(`\nПредмет закрыт ${this.olympiad.appeal_end}! \n\nВы можете выгрузить протокол.\n\nЗагрузка, редактирование не активно.`);
      } else {
        let file = document.getElementById('file-input');
        await this.processFile(file.files[0]);
        this.hideSave = false;
      }
    },

    schoolCodeNotExists() {
      let notExistingCodes = [];
      this.fileData.forEach(obj => {
        let notExists = !this.schools.find(element => parseInt(element.code, 10) === parseInt(obj['Код ОО'], 10));
        if (notExists) {
          notExistingCodes.push({
            schoolCode: obj['Код ОО'],
            surname: obj['Фамилия'],
            name: obj['Имя'],
            patronymic: obj['Отчество'],
            birthDate: obj['Дата рождения']
          });
        }
      });

      if (notExistingCodes.length > 0) {
        const div = document.createElement('div');
        const divHeader = document.createElement('span');
        const button = document.createElement('button');
        const buttonSpan =  document.createElement('span');

        div.className = "alert alert-warning alert-important mt-3";
        div.id = "school-code-warning";
        div.setAttribute("role", "alert");
        div.style.position = "absolute";
        div.style.width = "700px";
        div.style.left = "calc(50% - 700px / 2)";
        div.style.top = "252px";

        divHeader.style.width = "120px";
        divHeader.innerText = 'Проверьте в загружаемом файле поле "Код ОО" у следующих участников:';

        button.setAttribute("type", "button");
        button.className = "close";
        button.setAttribute("data-dismiss","alert");
        button.setAttribute("aria-label","alert");

        buttonSpan.setAttribute("aria-hidden", true);
        buttonSpan.style.color = "red";
        buttonSpan.style.fontWeight = "600";
        buttonSpan.innerHTML = '&times';

        button.appendChild(buttonSpan);
        div.appendChild(divHeader).appendChild(button);

        notExistingCodes.forEach(obj => {
          let li =  document.createElement('li');
          li.innerText = `${obj.surname} ${obj.name} ${!!obj.patronymic ? obj.patronymic : ''} (д.р. ${obj.birthDate}):  ${obj.schoolCode}`;
          div.appendChild(li);
        });


        document.getElementById('body-container').appendChild(div);

        let seconds = 0;
        let intervalId = setInterval(() => {
          seconds++;
          if (seconds > 60) {
            clearInterval(intervalId);
            !!document.getElementById("school-code-warning") ? document.getElementById("school-code-warning").remove() : false;
          }
        }, 1000);
      }
    },

    cancelImport() {
      this.fileData = [];
      this.pageData = [];
      this.hideSave = true;
      this.hidden = true;
      this.isSaved = true;
      this.total = 0;
      document.getElementById('file-input').value = '';
    },

    deleteTableRow(i) {
      if (this.olympiad.isOlympiadFinished) {
        alert(`\nПредмет закрыт ${this.olympiad.appeal_end}! \n\nВы можете выгрузить протокол.\n\nЗагрузка, редактирование не активны.`);
      } else {
        !!this.pageData[i].id ? this.deleteDbRecord(this.pageData[i].id) : false;
        this.fileData.splice(i - (this.current_page - 1) * this.pageSize, 1);
        this.pageData.splice(i, 1);
        if (this.pageData.length === 0 && this.current_page > 1) {
          this.current_page--;
        }

        if (this.fileData.length === 0) this.isSaved = true;

        this.paginate(this.fileData, this.pageSize, this.current_page);
        this.resetPageNumbers();
      }
    },

    async deleteDbRecord(id) {
      let current_page = this.current_page;
      this.mute = true;

      await fetch('/api/delete-protocol', {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json, text-plain, */*",
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-TOKEN": this.token
        },
        method: 'delete',
        credentials: "same-origin",
        body: JSON.stringify({id: id}) })
          .then((data) => {
            document.getElementById("success_alert").style.display = "block";
            document.getElementById("success_text").innerText = 'Запись успешно удалена из базы данных';
            this.timer();
            if (this.fileData.length === 0) this.isSaved = true;
          })
          .catch(function(error) {
          });
      await this.getDbData();
      current_page = this.pages.includes(current_page) ? current_page : current_page - 1;
      this.paginate(this.fileData, this.pageSize, current_page);
      this.mute = false;
    },

    async deleteWholeProtocol() {

      if (!this.olympiad.isInitialUploadActive) {
        alert(`\nПредмет закрыт ${this.olympiad.appeal_end}! \n\nВы можете выгрузить протокол.\n\nЗагрузка не активна.`);
      } else {
        let ids = [];
        let data = {};
        this.fileData.forEach(item => {
          if (!!item.id ) {
            ids.push(item.id);
          }
        });
        data = {stage: 2, olympiad_id: this.olympiadId, ids: ids};
        if (!confirm(`Внимание!!! Из базы данных будет полностью удален протокол по предмету "${!!this.olympiad ? this.olympiad.name : '... '}".`)) return;
        this.mute = true;

        await fetch('/api/delete-all', {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json, text-plain, */*",
            "X-Requested-With": "XMLHttpRequest",
            "X-CSRF-TOKEN": this.token
          },
          method: 'delete',
          credentials: "same-origin",
          body: JSON.stringify(data) })
            .then((response) => {
              document.getElementById("success_alert").style.display = "block";
              document.getElementById("success_text").innerText = 'Запись успешно удалена из базы данных';
              this.timer();
              this.dbData = [];
              this.fileData = [];
              this.pageData = [];
              this.total = 0;
              this.paginate(this.fileData, this.pageSize, 1);
            })
            .catch(function(error) {});
        this.mute = false;
      }
    },

    editTableRow() {
      if (this.olympiad.isOlympiadFinished) {
        alert(`\nПредмет закрыт ${this.olympiad.appeal_end}! \n\nВы можете выгрузить протокол.\n\nЗагрузка, редактирование не активны.`);
      } else {
        this.selectedPageData['Итоговый балл'] = this.finalMark(this.selectedPageData);
        if (this.modalRole === 1) {
          if (this.selectedPageData.isEdited && !!this.selectedPageData.id) {
            const obj = this.fileData.find(obj => obj.id === this.selectedPageData.id);
            Object.assign(obj, this.selectedPageData);
          } else {
            Object.assign(this.pageData[this.rowIndex], this.selectedPageData);
          }
        } else {
          this.fileData.push(this.selectedPageData);
        }
        !!this.pageData[this.rowIndex].id ? this.updateDbRecord() : false;
        this.closeModal();
      }
    },

    async updateDbRecord() {
      if (this.olympiad.isOlympiadFinished) {
        alert(`\nПредмет закрыт ${this.olympiad.appeal_end}! \n\nВы можете выгрузить протокол.\n\nЗагрузка, редактирование не активны.`);
      } else {
        let currentPage = this.current_page;
        if ( this.selectedPageData.isEdited ) {
          this.selectedPageData["Итоговый балл"] = this.finalMark(this.selectedPageData);
          delete this.selectedPageData.isEdited;
          Object.assign(this.pageData[this.rowIndex], this.selectedPageData);

          let rowData = JSON.stringify({"id": this.pageData[this.rowIndex].id, "data_row": JSON.stringify(this.pageData[this.rowIndex]) });
          this.mute = true;

          await fetch('/api/update-protocol', {
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json, text-plain, */*",
              "X-Requested-With": "XMLHttpRequest",
              "X-CSRF-TOKEN": this.token
            },
            method: 'PATCH',
            credentials: "same-origin",
            body: rowData  })
              .then((data) => {
                this.isModalVisible = false;
                document.getElementById("success_alert").style.display = "block";
                document.getElementById("success_text").innerText = 'Запись в базе данных успешно обновлена';
                this.timer(2);
                this.selectedPageData = {};
              })
              .catch(function(error) {});
          await this.getDbData();
          this.paginate(this.fileData, this.pageSize, currentPage);
          this.mute = false;
        }
      }
    },

    onPageSizeChange() {
      this.resetPageNumbers();
      this.paginate(this.fileData, this.pageSize, 1)
    },

    setDefaults() {
      this.hideSave=true;
      this.hidden = false;
      this.errored = false;
      this.fileData = [];
      this.pageData = [];
    },

    async store(objectsArray = []) {
      this.mute = true;
      const timer = ms => new Promise(res => setTimeout(res, ms));
      if (this.modalRole === 2) {
        objectsArray[0]["Итоговый балл"] = this.finalMark(objectsArray[0]);
      }
      objectsArray.forEach(obj => {
        obj.hasOwnProperty('isEdited') ? delete obj.isEdited : false;
      });
      const dataRows = this.prepareDataToSave(objectsArray);
      let dataRowLength = dataRows.length;
      if (dataRowLength <= 100) {
        let data = {dataRows: dataRows, olympiad_id: this.olympiadId};
        await this.saveToDB(data);
        await timer(1000);
      } else {
        let rows = [];
        let counter = 0;
        const loader = document.getElementById('mute');
        if (!!loader) {
          loader.innerHTML = `Осталось ${this.total} из ${this.total}` ;
          loader.style.fontSize = '40px';
          loader.style.fontWeight = '600';
        }
        for (let i = 0; i < dataRowLength; i++) {
          rows.push(dataRows[i]);
          if (i % 100 === 99) {

            let data = {dataRows: rows, olympiad_id: this.olympiadId};
            await this.saveToDB(data);
            counter = dataRowLength - i;
            !!loader ? loader.innerHTML = `Осталось ${counter - 1} из ${this.total}` : false;
            rows = [];
            await timer(1000);
          }
        }
        if (counter > 0) {
          let data = {dataRows: rows, olympiad_id: this.olympiadId};
          await this.saveToDB(data);
          rows = [];
        }
        !!loader ? loader.innerHTML = '' : false;
      }

      document.getElementById('file-input').value = '';

      this.cancelImport();
      await this.getDbData();
      !!this.selectedPageData ? this.closeModal() : false;
      this.selectedPageData = {};
      if (this.modalRole === 2) {
        let lastPage = this.pages[this.pages.length - 1];
        this.paginate(this.fileData, this.pageSize, lastPage);
      }
      this.isSaved = true;
      this.mute = false;
    },

    async saveToDB(data) {
      await fetch('/api/save-protocol', {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": this.token
        },
        method: 'post',
        credentials: "same-origin",
        body: JSON.stringify({olympData: data})
      })
          .then(function(response){
            return response.json();
          })
          .then(function(json){
            document.getElementById('file-input').value = '';
          })
          .catch(function(error){
            // console.log('check error: ', error)
          });
    },

    prepareDataToSave(objectsArray = []) {
      let stringifiedData = [];
      if (objectsArray.length === 0) {alert('Пустой список!'); return}
      objectsArray.forEach(item => {
        stringifiedData.push(JSON.stringify(item));
      });
      return stringifiedData;
    },

    prepareDataToShow(objectsArray = []) {
      this.dbData.forEach(item => {
        let dataRow = JSON.parse(item.data_row);
        let data = {
          '№': 0,
          'Район': '',
          'Предмет': '',
          'Направление': '',
          'Виды практик': '',
          'Код ОО': '',
          'Наименование ОО': '',
          'Фамилия': '',
          'Имя': '',
          'Отчество': '',
          'Дата рождения': null,
          'Пол': '',
          'Участник с ОВЗ (да/нет)': '',
          'Класс обучения': null,
          'Класс участия': null,
          'Балл': null,
          'Добавлено баллов по итогам апелляций': null,
          'Итоговый балл': null,
          'Максимальный балл': null,
          'Статус': '',
          'Призёр республиканского этапа прошлого года': '',
          'ФИО учителя': '',
          'Место работы учителя': ''
        };
        if (this.olympiad.name.toLowerCase() !== "технология") {
          delete data['Направление'];
          delete data['Виды практик'];
        }
        for (let key in dataRow) {
          data[key] = dataRow[key];
          if (key === "Балл" || key === "Добавлено баллов по итогам апелляций" || key === "Итоговый балл") {
            if (!!data[key]) {
              data[key] = Math.round(parseFloat(data[key].toString().replace(',', '.')) * 100) / 100;
              data[key] = data[key].toString().replace('.', ',');
            }
          }
        }
        data['№'] = item.id;
        data.id = item.id;
        objectsArray.push(data);
      });
    },

    async loadSubjects() {
      this.mute = true;
      const year = new Date().getFullYear();
      await fetch(`../api/olympiad-data?stage=2&year=${year}`)
          .then(res => res.json())
          .then(json => this.subjects = json)
          .catch(error => {
            this.errored = true;
          });
      this.mute = false;
    },

    async saveToExcel()  {
      this.mute = true;
      let data = [];
      this.prepareDataToShow(data);
      data.forEach(obj => {
        obj['Код ОО'] = Number(obj['Код ОО']);
        let school = this.schools.find(element => element.code === obj['Код ОО']);
        let areaName = '';
        if (!!obj["Дата рождения"]) {
          let birthDate = moment(obj["Дата рождения"], 'DD.MM.YYYY').format('YYYY-MM-DD');
          obj["Дата рождения"] = this.JSDateToExcelDate(new Date(birthDate));
        } else {
          obj["Дата рождения"] = '';
        }

        if (!!obj["Класс обучения"]) {
          obj["Класс обучения"] =  Number(obj["Класс обучения"]);
        } else {
          obj["Класс обучения"] = ''
        }

        if (!!obj["Класс участия"]) {
          obj["Класс участия"] =  Number(obj["Класс участия"]);
        } else {
          obj["Класс участия"] = ''
        }

        if (!!obj["Балл"]) {
          obj["Балл"] =  obj["Балл"].replace(/,/, '.');
          obj["Балл"] =  Number(obj["Балл"]);
        } else {
          obj["Балл"] = '';
        }

        if (!!obj["Добавлено баллов по итогам апелляций"]) {
          obj["Добавлено баллов по итогам апелляций"] =  obj["Добавлено баллов по итогам апелляций"].replace(/,/, '.');
          obj["Добавлено баллов по итогам апелляций"] =  Number(obj["Добавлено баллов по итогам апелляций"]);
        } else {
          obj["Добавлено баллов по итогам апелляций"] = '';
        }

        if (!!obj["Итоговый балл"]) {
          obj["Итоговый балл"] = obj["Итоговый балл"].replace(/,/, '.');
          obj["Итоговый балл"] = Number(obj["Итоговый балл"]);
        } else {
          obj["Итоговый балл"] = '';
        }

        if(!!obj["Максимальный балл"]) {
          obj["Максимальный балл"] =  obj["Максимальный балл"].replace(/,/, '.');
          obj["Максимальный балл"] =  Number(obj["Максимальный балл"]);
        } else {
          obj["Максимальный балл"] = '';
        }

        !!obj.id ? delete obj.id : false;

        if (!!school && !!school.name) {

          obj["Наименование ОО"] =  school.name;
          areaName = school.areaName;
        } else {
          areaName = this.schools[0].governmentName;
        }

        obj["Предмет"] = this.olympiad.name;
        obj["Район"] = areaName;
      });
      let res = alasql('SELECT * INTO XLSX("' + `Предмет_${this.olympiad.code}.xlsx` + '",?) FROM ?', [[{ headers:true }], [data]]);
      this.mute = false;
    },

    finalMark(participantOlymp) {
      let primaryMark = participantOlymp["Балл"] !== null && isNaN(participantOlymp["Балл"]) ? participantOlymp["Балл"].replace(',', '.') : participantOlymp["Балл"];
      primaryMark = isNaN(Number(primaryMark)) ? 0 : Number(primaryMark);
      let appealMark = !!participantOlymp["Добавлено баллов по итогам апелляций"] ? Number(participantOlymp["Добавлено баллов по итогам апелляций"].replace(',', '.')) : 0;
      let finalMark = primaryMark + appealMark;
      return finalMark.toString().replace('.', ',');
    },

    JSDateToExcelDate(inDate) {
      let returnDateTime = 25569.0 + ((inDate.getTime() - (inDate.getTimezoneOffset() * 60 * 1000)) / (1000 * 60 * 60 * 24));
      return parseInt(returnDateTime, 10);
    },

    dateFormat(date) {
      return moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');
    },

    timer(limit = 3) {
      let seconds = 0;
      let intervalId = setInterval(() => {
        seconds++;
        if (seconds > limit) {
          clearInterval(intervalId);
          document.getElementById("success_text").innerText = "";
          document.getElementById("success_alert").style.display = "none";
        }
      }, 1000);
    },

    timeCounter() {
      let timeLimit = 18;
      this.minutes = timeLimit;
      let intervalId = setInterval(() => {
        this.minutes--;
        if (this.minutes <= 0) {
          clearInterval(intervalId);
          this.minutes = timeLimit;
        }
      }, 600000);
    },

    checkPage: function(page){
      return page === this.current_page;
    },

    nextPage() {
      let i = this.current_page;
      i++;
      if (i <= this.pages[this.pages.length - 1]) {
        this.current_page = i;
        this.paginate(this.fileData, this.pageSize, this.current_page);
      }
    },

    paginate(array, pageSize, pageNumber) {
      this.pageData = array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
      this.current_page = pageNumber;
    },

    previousPage() {
      let i = this.current_page;
      i--;
      if (i >= 1) {
        this.current_page = i;
        this.paginate(this.fileData, this.pageSize, this.current_page);
      }
    },

    resetPageNumbers() {
      this.pages = [];
      this.total = this.fileData.length;
      for (let i = 1; i <= Math.ceil(this.total/this.pageSize); i++) {
        this.pages.push(i);
      }
    },

    showModal(i = null) {
      if (this.olympiad.isOlympiadFinished) {
        alert(`\nПредмет закрыт ${this.olympiad.appeal_end}! \n\nВы можете выгрузить протокол.\n\nЗагрузка, редактирование не активны.`);
      } else {
        if (i === null) {
          this.clickCount++;
          this.modalRole = 2;
          this.selectedPageData = {
            '№': 1, 'Фамилия': '', 'Имя': '', 'Отчество': '', 'Дата рождения': '',
            'Пол': '', 'Участник с ОВЗ (да/нет)': 'нет', 'Класс обучения': null, 'Класс участия': null,
            'Балл': null, 'Максимальный балл': null, 'Статус': '',
            'Призёр муниципального этапа прошлого года': '',
            'ФИО учителя': '', 'Место работы учителя': '', 'subjectName': '',
            isEdited: false
          };
          this.modalTitle = 'Ввод новых данных';
        } else {
          this.rowIndex = i;
          this.clickCount++;
          Object.assign(this.selectedPageData, this.pageData[i]);
          this.selectedPageData['Отчество'] = !!this.pageData[i]['Отчество'] ? this.pageData[i]['Отчество'] : '';
          this.selectedPageData['Дата рождения'] = !!this.selectedPageData['Дата рождения'] ? moment(this.selectedPageData['Дата рождения'], 'DD.MM.YYYY').format('YYYY-MM-DD') : '';
          Object.defineProperty(this.selectedPageData, "isEdited", {value: false, writable: true, configurable: true});
          this.modalRole = 1;
          this.modalTitle = 'Редактирование данных';
        }
        this.selectedPageData.subjectName = this.olympiad.name.toLowerCase();
        this.isModalVisible = true;
      }
    },

    closeModal() {
      this.selectedPageData = {};
      this.isModalVisible = false;
      this.mute = false;
      this.modalRoleState = 0;
    },
  },

  watch: {
    mute(val) {
      document.getElementById('mute').className = val ? "on" : "";
      document.getElementById('mute').innerHTML = val ? '<div class="loader"></div>' : '';
    },

    minutes(val) {
      this.loadSubjects();
    },

    olympiad(val) {
      this.showTechnology = val.name.toLowerCase() === "технология";
    }
  },

  mounted() {
    document.getElementById("success_alert").style.display = "none";
    this.token = $("input[name='_token']").val();
    this.loadSubjects();
    this.timeCounter();
  },
}
</script>

<style scoped>
table th, td {
  padding: 2px;
}
</style>