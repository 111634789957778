<template>
    <div class="container">
        <div id="mute"></div>
        <div class="d-flex justify-content-between mt-0 mb-0 p-0">
            <div class="form-group">
                <label>
                    <input
                        class="search bg-white rounded px-2"
                        id="search" name="query"
                        type="search"
                        placeholder="Поиск"
                        v-model="query"
                        @keyup.stop="search(query)"
                    />
                </label>
                <button
                    class="clear-search"
                    type="button"
                    aria-label="clear-search"
                    v-show="query !== ''"
                    @click="search(query='')"
                    title="Очистить поиск"
                >
                    <span
                        aria-hidden="true"
                        style="font-weight: 600;"
                    >
                        &times;
                    </span>
                </button>
            </div>
            <div>
                <label>Показать по
                    <select
                        class="rounded bg-white" id="showcount" name="per_page"
                        v-model="per_page"
                        @change="getData()"
                    >
                        <option
                            v-for="option in options"
                            :key="'opt' + option"
                            v-bind:value="option"
                        >
                            {{option}}
                        </option>
                    </select>
                </label>
            </div>
            <div class="mr-1" v-if="total">
                <span class="align-middle m-0">Всего: {{total}}</span>
            </div>
            <div class="mr-4" v-else>
                <span class="align-middle m-0">Всего:</span>
            </div>
        </div>

        <table class="table table-bordered table-striped table-hover table-sm mb-3">
            <thead>
            <tr class="align-middle text-center">
                <th>№</th>
                <th>Код УО</th>
                <th>Наименование МСУ</th>
                <th>ФИО ответственного</th>
                <th>E-mail адрес</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr class="align-middle" v-for="(item, index) in gridData" :key="'row' + index"
            >
                <td class="align-middle text-center"><span>{{ index + 1 + (current_page - 1) * per_page }}</span></td>
                <td class="align-middle text-center">{{item.governmentCode}}</td>
                <td class="align-middle"><span>{{item.governmentName}}</span></td>
                <td class="align-middle">
                    <span v-if="item.name && !isEditing || item.id !== editngItemId">{{ item.name }}</span>
                    <input
                        v-else-if="isEditing"
                        class="from-control user-data"
                        type="text" name="name"
                        v-model="userName"
                        v-bind:id="'n' + index"
                    >
                </td>
                <td class="align-middle">
                    <span v-if="item.email && !isEditing || item.id !== editngItemId">{{item.email}}</span>
                    <input
                        v-else-if="isEditing"
                        class="from-control user-data"
                        type="email" name="email"
                        v-model="userEmail"
                        v-bind:id="'m' + index"
                    >
                </td>
                <td class="align-middle text-center">
                    <div class="btn-group btn-group-sm m-0 p-0">
                        <button
                            class="btn btn-success"
                            :disabled="isEditing && item.id == editngItemId ? 'disabled' : false"
                            @click="itemDataEdit(item)"
                        >
                            Изменить
                        </button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>

        <div class="row col-md-12 mb-2 align-items-center" id="gridFooter" v-if="total">
            <div class="col-md m-0">
                Страница {{ current_page }} из {{ last_page }}
            </div>
            <div class="col-md m-0 d-flex justify-content-center">
                <nav aria-label="Page navigation">
                    <ul class="pagination pagination-sm m-0">
                        <li class="page-item"><a class="page-link" @click.prevent="getData(first_page_url)"> &#x3c;&#x3c; </a></li>
                        <li class="page-item" v-if="checkUrlNotNull(prev_page_url)">
                            <a class="page-link" @click.prevent="getData(prev_page_url)" > &#x3c; </a>
                        </li>
                        <li class="page-item"
                            v-for="page in pages"
                            :key="'page' + page"
                            v-show="page > current_page - 3 && page < current_page + 3"
                            v-bind:class="{'active': checkPage(page)}"
                        >
                            <a class="page-link" @click.prevent="getData(page)">{{ page }}</a>
                        </li>
                        <li class="page-item" v-if="checkUrlNotNull(next_page_url)">
                            <a class="page-link" @click.prevent="getData(next_page_url)"> &#x3e; </a>
                        </li>
                        <li class="page-item"><a class="page-link" @click.prevent="getData(last_page_url)"> &#x3e;&#x3e; </a></li>
                    </ul>
                </nav>
            </div>
            <div class="col-md m-0 d-flex justify-content-end align-items-center" id="for_page_button">
                <div class="p-0 m-0">
                    <input class="w-25 number-input text-center border border-primary rounded float-right mr-2"
                           v-model="go_to_page"
                           @keyup="checkPageNumber(go_to_page)"
                           @keyup.enter.self.stop.prevent="getData(go_to_page)"
                           placeholder="стр."
                    />
                </div>
                <div>
                    <button
                        class="btn btn-sm btn-primary" id="go_to_page"
                        :disabled="!go_to_page"
                        @click.prevent="getData(go_to_page)"
                    >
                        Перейти
                    </button>
                </div>
            </div>
        </div>
        <hr/>
        <div style="font-size: 18px">Загрузка пользователей из файла (<button type="button" class="p-0 btn btn-link" @click="getTemplateFile()">Скачать форму для ввода новых пользователей</button>)</div>
        <div class="alert alert-warning my-3">
            <span style="font-weight:600;font-size:16px">Внимание! При загрузке из файла из базы данных будет удалена вся информация связанная со старыми пользователями.</span>
        </div>
        <div class="d-flex">
            <div class="d-flex justify-content-center">
                <div class="row align-items-center px-3" id="list-upload">
                    <label for="file-input"></label>
                    <input
                        type="file" id="file-input" ref="file" accept=".xls, .xlsx, .ods"
                    />

                    <div class="m-0 p-0 ml-2">
                        <button
                            class="btn btn-outline-secondary"
                            :hidden="!hideSave"
                            :disabled="false"
                            @click="readData"
                        >
                            Считать
                        </button>
                        <button
                            class="btn btn-outline-success" id="submit"
                            :hidden="hideSave"
                            @click="saveGovUsers"
                        >
                            Сохранить
                        </button>
                        <button
                            class="btn btn-outline-danger" id="cancel"
                            @click="cancelImport"
                        >
                            Отмена
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="fileData.length > 0">
            <table class="table table-bordered table-striped table-hover table-responsive-lg mt-3 mb-3">
                <thead>
                <tr>
                    <th class="align-middle text-center">№</th>
                    <th class="align-middle text-center">Код АТЕ</th>
                    <th class="align-middle text-center">Наименование АТЕ</th>
                    <th class="align-middle text-center">ФИО ответсвтенного</th>
                    <th class="align-middle text-center">Пароль</th>
                    <th class="align-middle text-center">Пароль</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in fileData" :key="'tr' + index">
                    <td class="align-middle text-center row-num">{{ item["№"] }}</td>
                    <td class="align-middle text-center">{{item['Код АТЕ']}}</td>
                    <td class="align-middle">{{item['Наименование АТЕ']}}</td>
                    <td class="align-middle">{{ item['ФИО ответсвтенного'] }}</td>
                    <td class="align-middle">{{item['Адрес e-mail']}}</td>
                    <td class="align-middle text-center">{{item['Пароль']}}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <hr>

        <section v-if="errored" class="alert alert-danger alert-dismissible alert-important mt-3" role="alert"
                 id="failed_alert">
            <span id="failed_text">К сожалению, в данный момент мы не можем получить эту информацию, пожалуйста, попробуйте вернуться
                позже.</span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true" style="color: red; font-weight: 600;">&times;</span>
            </button>
        </section>

        <div class="alert alert-success alert-dismissible alert-important mt-3" role="alert" id="success_alert">
            <span id="success_text"></span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true" style="color: red; font-weight: 600;">&times;</span>
            </button>
        </div>

        <button
                id="modalButton"
                type="button"
                class="btn"
                @click="showModal"
                hidden
        >
            Open Modal
        </button>
        <modal
                v-show="isModalVisible"
                :data = "selectedItemData"
                :title="modalTitle"
                @close="closeModal"
                @save="savePersonData(selectedItemData)"
        />
    </div>
</template>

<script>
    import modal from './EduDepartmentModal';
    export default {
        components: { modal },
        name: "EduDepartmentResponsibles",
        data: function () {
            return {
                allGovs: [],
                allSelected: false,
                current_page: null,
                current_page_url: null,
                isEditing: false,
                editngItemId: null,
                errored: false,
                fileData: [],
                first_page_url: null,
                go_to_page: '',
                gridData: [],
                hideSave: true,
                mute: false,
                last_page: null,
                last_page_url: null,
                next_page_url: null,
                options: [5, 10, 15, 20],
                pages: [],
                per_page: 5,
                prev_page_url: null,
                query: '',
                sortKey: 'governmentCode',
                sortOrder: 2,
                stored: false,
                token: null,
                total: null,
                userEmail: '',
                userName: '',
                isModalVisible: false,
                modalTitle: 'Данные ответственного за олимпиаду',
                selectedItemData : [],
            }
        },

        methods: {
            loadData() {
                this.pages = [];
                this.fetchData();
            },

            async fetchData () {
                this.mute = true;
                await fetch(`/api/education-department?paginate=${this.per_page}`)
                .then(res => res.json())
                .then(json => {
                    this.gridData = json.rows.data;
                    this.total = json.rows.total;
                    this.last_page = json.rows.last_page;
                    this.next_page_url = json.rows.next_page_url;
                    this.prev_page_url= json.rows.prev_page_url;
                    this.first_page_url = '../api/education-department?page=1';
                    this.last_page_url = '../api/education-department?page=' + this.last_page;
                    this.current_page = json.rows.current_page;
                })
                .catch(error => {
                    this.errored = true;
                });
                this.resetPageNumbers();
                this.mute = false;
            },

            getData: function (request){
                let getPage;
                switch (request) {
                    case this.prev_page_url:
                        getPage = this.prev_page_url
                            + '&column=' + this.sortKey
                            + '&direction=' + this.sortOrder
                            + '&paginate=' + this.per_page;
                        break;
                    case this.next_page_url:
                        getPage = this.next_page_url
                            + '&column=' + this.sortKey
                            + '&direction=' + this.sortOrder
                            + '&paginate=' + this.per_page;
                        break;
                    case this.first_page_url:
                        getPage = this.first_page_url
                            + '&column=' + this.sortKey
                            + '&direction=' + this.sortOrder
                            + '&paginate=' + this.per_page;
                        break;
                    case this.last_page_url:
                        getPage = this.last_page_url
                            + '&column=' + this.sortKey
                            + '&direction=' + this.sortOrder
                            + '&paginate=' + this.per_page;
                        break;
                    case this.query:
                        getPage = '../api/education-department?'
                            + this.searchingKeyword()
                            + '&column=' + this.sortKey
                            + '&direction=' + this.sortOrder
                            + '&paginate=' + this.per_page;
                        break;
                    case this.go_to_page:
                        if ( this.go_to_page !== '') {
                            getPage = '../api/education-department?'
                                + 'page=' + this.go_to_page
                                + '&column=' + this.sortKey
                                + '&direction=' + this.sortOrder
                                + this.searchingKeyword()
                                + '&paginate=' + this.per_page;
                            this.clearPageNumberInputBox();
                        }
                        break;
                    default :
                        getPage = '../api/education-department?' +
                            'page=' + request +
                            '&column=' + this.sortKey +
                            '&direction=' + this.sortOrder + this.searchingKeyword() +
                            '&paginate=' + this.per_page;
                        break;
                }

                if (this.query === '' && getPage != null){
                    fetch(getPage)
                    .then(res => res.json())
                    .then(json => {
                        this.gridData = json.rows.data;
                        this.total = json.rows.total;
                        this.last_page =  json.rows.last_page;
                        this.next_page_url = json.rows.next_page_url;
                        this.prev_page_url = json.rows.prev_page_url;
                        this.last_page_url = '../api/education-department?page='
                            + this.last_page
                            + '&paginate=' + this.per_page;
                        this.current_page = json.rows.current_page;
                    })
                    .catch(error => {
                        this.errored = true;
                    });
                    this.mute = false
                } else {
                    if (getPage != null){
                        fetch(getPage)
                        .then(res => res.json())
                        .then(json => {
                            this.gridData = json.rows.data;
                            this.total = json.rows.total;
                            this.last_page =  json.rows.last_page;
                            this.next_page_url = (json.rows.next_page_url == null) ? null :
                                json.rows.next_page_url
                                + '&keyword=' + this.query
                                + '&paginate=' + this.per_page;
                            this.prev_page_url = (json.rows.prev_page_url == null) ? null :
                                json.rows.prev_page_url
                                + '&keyword=' +this.query
                                + '&paginate=' + this.per_page;
                            this.first_page_url = '../api/education-department?page=1&keyword='
                                + this.query
                                + '&paginate=' + this.per_page;
                            this.last_page_url = '../api/education-department?page='
                                + this.last_page + '&keyword='
                                + this.query + '&paginate='
                                + this.per_page;
                            this.current_page = json.rows.current_page;
                        })
                        .catch(error => {
                            this.errored = true;
                        });
                        this.mute = false;
                    }
                }
                this.resetPageNumbers();
                this.allSelected = false;
            },

            searchingKeyword() {
                let keyword = this.query != '' ? '&keyword=' + this.query : '';
                return keyword;
            },

            search (query) {
                query !== '' ? this.getData(query) : this.fetchData();
                this.resetPageNumbers();
            },

            sortBy (key) {
                this.sortKey = key;
                this.sortOrder = (this.sortOrder === 1) ? -1 : 1;
                this.getData(1);
            },

            checkPage: function(page){
                return page === this.current_page;
            },

            checkPageNumber(){
                let newLine = "\r\n";
                if (!this.pageInRange()) {
                    alert('Пожалуйста, введите действительный номер страницы! '+ newLine +' (Номера страниц от 1 до ' + this.last_page + ')');
                    this.clearPageNumberInputBox();
                }
            },

            resetPageNumbers: function(){
                this.pages = [];
                for (let i = 1; i <= Math.ceil(this.total/this.per_page); i++) {
                    this.pages.push(i);
                }
            },

            checkUrlNotNull: function(url){
                return url != null;
            },

            clearPageNumberInputBox: function(){
                return this.go_to_page = '';
            },

            pageInRange: function(){
                return this.go_to_page <= parseInt(this.last_page);
            },

            savePersonData(selectedItem) {
                let item = selectedItem[0];
                if (item.id === null) {
                    let data = {
                        name : item.name,
                        email : item.email,
                        password: item.password,
                        gov_code : item.governmentCode,
                        gov_id: item.gov_id
                    };

                    this.addUser(data);
                } else {
                    let data = {
                        id: item.id,
                        name : item.name,
                        email : item.email,
                        password: item.password
                    };

                    this.updateUser(data);
                };
                this.closeModal();
            },

            async addUser(data) {
                this.mute = true;
                await fetch('/add_user', {
                    headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json, text-plain, */*",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-CSRF-TOKEN": this.token
                    },
                    method: 'post',
                    credentials: "same-origin",
                    body: JSON.stringify({ person: data })
                })
                .then(response => response.json())
                .then(result => {
                    this.stored = true;
                    this.getData();
                    document.getElementById("success_alert").style.display = "block";
                    document.getElementById("success_text").innerText = result.message;
                    this.timer(4);
                })
                .catch(function(error) {
                    this.errored = true;
                });
                this.srored = false;
                this.mute = false;
                this.selectedItemData = [];
            },

            async updateUser(data) {
                this.mute = true;
                await fetch('/users', {
                    headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json, text-plain, */*",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-CSRF-TOKEN": this.token
                    },
                    method: 'post',
                    credentials: "same-origin",
                    body: JSON.stringify({ id: data.id, data: data })
                })
                .then(response => response.json())
                .then(result => {
                    this.stored = true;
                    this.getData();
                    document.getElementById("success_alert").style.display = "block";
                    document.getElementById("success_text").innerText = result.message;
                    this.timer(4);
                })
                .catch(function(error) {
                    this.errored = true;
                });
                this.srored = false
                this.isEditing = false;
                this.editngItemId = null;
                this.mute = false;
                this.selectedItemData = [];
            },

            timer(limit = 3) {
                let seconds = 0;
                let intervalId = setInterval(() => {
                    seconds++;
                    if (seconds > limit) {
                        clearInterval(intervalId);
                        document.getElementById("success_text").innerText = "";
                        document.getElementById("success_alert").style.display = "none";
                    }
                }, 1000);
            },

            async getAllGov() {
                this.mute = true;
                await fetch('/api/education-department?paginate=300')
                .then(res => res.json())
                .then(json => this.allGovs = json.rows.data)
                .catch(error => {
                    this.errored = true;
                });
                this.mute = false;
            },

            async getTemplateFile() {
                await this.getAllGov();
                let data = [];
                let i = 1;

                this.allGovs.forEach(obj => {
                    let tempObj = {'№': i, 'Код АТЕ': '', 'Наименование АТЕ': '', 'ФИО ответсвтенного': '', 'Адрес e-mail': '', 'Пароль': ''};
                    tempObj['Код АТЕ'] = obj.governmentCode;
                    tempObj['Наименование АТЕ'] = obj.governmentName;
                    tempObj['ФИО ответсвтенного'] = !!obj.name ? obj.name : '';
                    tempObj['Адрес e-mail'] = !!obj.email ? obj.email : '';
                    tempObj['Пароль'] = '';
                    i++;
                    data.push(tempObj);
                });

                let options = [{
                    headers:true,
                    column: {style:{Font:{Bold:"1"}}},
                    columns: [
                        { columnid: '№', width: 40 },
                        { columnid: 'Код АТЕ', width: 20 },
                        { columnid: 'Наименование АТЕ', width: 150 },
                        { columnid: 'ФИО ответсвтенного', width: 250 },
                        { columnid: 'Адрес e-mail', width: 150 },
                        { columnid: 'Пароль', width: 90 },
                    ],
                }];
                let res = alasql('SELECT * INTO XLSX("Форма_для_ввода_ответственных_в_ОУО.xlsx",?) FROM ?', [options, [data]]);
            },

            showModal() {
                this.isModalVisible = true;
            },

            closeModal() {
                this.isModalVisible = false;
                this.selectedItemData = [];
                this.mute = false;
            },
            itemDataEdit (item) {
                this.selectedItemData = [];
                this.selectedItemData.push(item);
                this.showModal();
            },
            readFileAsync(file) {
                return new Promise((resolve, reject) => {
                    let reader = new FileReader();
                    reader.onload = () => {
                        resolve(reader.result);
                    };
                    reader.onerror = reject;
                    reader.readAsArrayBuffer(file);
                })
            },

            arrayBufferToString(arrayBuffer) {
                let data = new Uint8Array(arrayBuffer);
                let workbook = XLSX.read(data, {type: 'array'});
                let sheetName = workbook.SheetNames[0];
                let worksheet = workbook.Sheets[sheetName];
                let isPaswordFieldEmpty = true;
                this.fileData = [];

                worksheet = XLSX.utils.sheet_to_json(worksheet);
                worksheet.forEach(obj => {
                    if (!!obj["Код АТЕ"] && !!obj["ФИО ответсвтенного"] && !!obj["Адрес e-mail"] && !!obj["Пароль"]) {
                        let tmpObj = {};
                        for (const [key, value] of Object.entries(obj)) {
                            if (typeof value === 'string') {
                                Object.defineProperty(obj, key, {
                                    value: value.trim().replace('\n', ' ').replace('  ', ' '),
                                });
                            }
                            tmpObj[key] = obj[key];
                        }
                        this.fileData.push(tmpObj);
                    }
                });
                worksheet = [];
            },

            async processFile(file) {
                this.mute = true;
                try {
                    let arrayBuffer = await this.readFileAsync(file);
                    this.arrayBufferToString(arrayBuffer);
                } catch (error) {
                    console.log(error);
                }
                this.mute = false;
            },

            async readData() {
                let file = document.getElementById('file-input');
                if (file.files.length > 0) {
                    this.mute = true;
                    await this.processFile(file.files[0]);
                    this.mute = false;
                    if (this.fileData.length === 0) {
                        this.cancelImport();
                        alert('Опс...\nЧто-то не так!\nПроверьте содержимое загружаемого файла!\nВ нём имеется полностью пустой столбец!\nВ файле должна быть хотя бы одна строка, в которой заполнены все поля.');
                    } else {
                        this.hideSave = false;
                        alert('Внимание!\nЕсли на каких-то строках в файле одно из следующих полей\n"Код АТЕ", "ФИО ответсвтенного",\n "Адрес e-mail", "Пароль" не заполнен, они будут пропущены.');
                    }
                } else {
                    alert('Файл не выбран');
                }
            },

            cancelImport() {
                this.fileData = [];
                this.hideSave = true;
                document.getElementById('file-input').value = '';
            },

            async saveGovUsers() {
                await this.getAllGov();
                let ids = [];
                this.allGovs.forEach(item => {
                    if (!!item.id ) {
                        ids.push(item.id);
                    }
                });
                this.mute = true;
                 await fetch('/add-gov-users', {
                    headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json, text-plain, */*",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-CSRF-TOKEN": this.token
                    },
                    method: 'post',
                    credentials: "same-origin",
                    body: JSON.stringify({ users:  this.fileData, ids: ids })
                })
                .then(response => response)
                .then(result => {
                    this.stored = true;
                    this.getData();
                    document.getElementById("success_alert").style.display = "block";
                    document.getElementById("success_text").innerText = "Пользователи сохранены";
                    this.timer(3);
                })
                .catch(function(error) {
                    this.errored = true;
                });
                this.mute = false;
                this.cancelImport();
                this.fileData = [];
                this.hideSave = true;
            }
        },

        mounted() {
            this.token = $("input[name='_token']").val();
            this.loadData();
            document.getElementById("success_alert").style.display = "none";
        },

        watch: {
            mute(val) {
                document.getElementById('mute').className = val ? "on" : "";
                document.getElementById('mute').innerHTML = val ? '<div class="loader"></div>' : '';
            },

            total(val) {
                this.resetPageNumbers();
            }
        },
    }
</script>

<style scoped>
    input[type=text]::-ms-clear {  display: none; width : 0; height: 0; }
    input[type=text]::-ms-reveal {  display: none; width : 0; height: 0; }
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration { display: none; }
    
    input[type="checkbox"] {
        height: 15px;
        width: 15px;
    }
    table thead th {
        vertical-align: middle;
    }
    td > input {
        min-width: 230px;
    }
</style>
